import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

interface OrderStatusModalProps {
  isOpen: boolean;
  toggle: () => void;
  selectedRoute: string;
  setSelectedRoute: (status: string) => void;
  ordersListRoute: { id: string; name: string }[];
  handleRouteUpdate: () => void;
}

const OrderRouteUpdate: React.FC<OrderStatusModalProps> = ({
  isOpen,
  toggle,
  selectedRoute,
  setSelectedRoute,
  ordersListRoute,
  handleRouteUpdate,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Update Driver Route</ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <label className="form-label" htmlFor="status-select">
          Select Route
          </label>
          <select
            className="form-control"
            id="status-select"
            name="orderStatus"
            onChange={(e) => setSelectedRoute(e.target.value)}
            value={selectedRoute}
          >
            <option value="">Select Route</option>
            {ordersListRoute.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Discard
        </Button>
        <Button color="primary" onClick={handleRouteUpdate}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OrderRouteUpdate;
