/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import TableContainer from "../../Components/Common/TableContainer";
import {
  checkDuplicateEmail,
  createEmployee,
  deleteEmployee,
  listEmployee,
  updateEmployee,
} from "./_requests";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { ListRole } from "../../pages/Roles/_requests";
import { profile } from "console";

const Employee = (data: any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [Employee, setEmployee] = useState<any>([]);
  const [Roles, setRoles] = useState<any>([]);
  const [Employeeaccess, setEmployeeaccess] = useState<any>([]);
  const { userProfile } = useProfile();
  const [modal, setModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [EmployeeToDeleteId, setEmployeeToDeleteId] = useState<string>("");
  const [EmployeeToDeleteName, setEmployeeToDeleteName] = useState<string>("");
  const [Duplicate, setDuplicate] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<any>();
  const [Email, setEmail] = useState("");
  const toggle = useCallback(() => {
    setModal(!modal);
    setDuplicate("");
  }, [modal]);

  useEffect(() => {
    // getCRUD(userProfile?.role, ).then((res: any) => {
    //   setEmployeeaccess(res.Data[0]);
    // });
  }, []);
  const [previewImage, setPreviewImage] = useState<string | null>(null); // Optional for preview

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];

      // Store the file object in state
      setSelectedImage(file);

      // Optional: Generate a base64 preview (for image preview purposes)
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setPreviewImage(e.target.result as string); // Store the base64 string for preview
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    fetchEmployee();
  }, []);

  const fetchEmployee = () => {
    listEmployee().then((res: any) => {
      setEmployee(res.data);
    });
  };

  useEffect(() => {
    ListRole().then((res: any) => {
      setRoles(res);
    });
  }, [modal, deleteModalOpen]);

  // Delete Data
  const onClickDelete = useCallback(
    (employee: any) => {
      setEmployeeToDeleteName(employee.name);
      setEmployeeToDeleteId(employee.id);

      setEmployeeaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.remove === 1) {
          toggleDeleteModal();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [setEmployeeToDeleteName, setEmployeeToDeleteId, toggleDeleteModal]
  );

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: "",
      roleId: "",
      contactNumber: "",
      email: "",
      address: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      roleId: Yup.string().required("Role is required"),
      contactNumber: Yup.string().required("Phone Number is required"),
      email: Yup.string().required("Email is required"),
      address: Yup.string().required("Address is required"),
      password: isEdit
        ? Yup.string()
        : Yup.string().required("Password is required"),
    }),
    onSubmit: async (values: any) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("roleId", values.roleId);
        formData.append("contactNumber", values.contactNumber);
        formData.append("address", values.address);
        formData.append(isEdit ? "updated_by" : "created_by" , userProfile?.userId);
        if (!isEdit) {
          formData.append("password", values.password);
        }

        if (selectedImage) {
          formData.append(isEdit ? "profileNew" : "profile" , selectedImage);
        }

        if (isEdit) {
          await updateEmployee(formData, values.id);
          toast.success("Employee updated Successfully", { autoClose: 3000 });
        } else {
          await createEmployee(formData);
          toast.success("Employee created Successfully", { autoClose: 3000 });
        }

        fetchEmployee();
        validation.resetForm();
        setSelectedImage("");
        toggle();
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong", { autoClose: 3000 });
      }
    },
  });

  const confirmDelete = async (employeeId: any) => {
    await deleteEmployee(employeeId ,userProfile?.userId);
    toggleDeleteModal();
    toast.success("Employee deleted Successfully", { autoClose: 3000 });
    fetchEmployee();
  };

  // Update Data
  const handleEmployeeClick = useCallback(
    (Employee: any) => {
      validation.setValues({
        id: Employee.id,
        name: Employee.name,
        email: Employee.email,
        roleId: Employee.roleId,
        contactNumber: Employee.contactNumber,
        address: Employee.address,
      });
      setPreviewImage(Employee.profile);
      setEmail(Employee.email);
      setIsEdit(true);

      setEmployeeaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.edit === 1) {
        toggle();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [toggle, validation]
  );

  const DuplicateCheck = (val: any) => {
    try {
      checkDuplicateEmail(val).then((response: any) => {
        if (isEdit) {
          if (response?.isDuplicate === true && Email !== val) {
            setDuplicate("Email already exists");
          } else {
            setDuplicate("");
          }
        } else {
          if (response?.isDuplicate === true) {
            setDuplicate("Email already exist");
          } else {
            setDuplicate("");
          }
        }
      });
    } catch (error) {
      console.error("Error checking for duplicate role:", error);
    }
  };

  // Employees Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={cell.row.original.profile}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to="/apps-ecommerce-product-details"
                    className="text-body"
                  >
                    {" "}
                    {cell.getValue()}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        header: "Role",
        accessorKey: "role.roleName",
        enableColumnFilter: false,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const EmployeeData = cellProps.row.original;
                    handleEmployeeClick(EmployeeData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const EmployeeData = cellProps.row.original;
                    onClickDelete(EmployeeData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleEmployeeClick, onClickDelete]
  );

  const addEmployee = () => {
    // if (Employeeaccess?.add === 1) {
    setIsEdit(false);
    setPreviewImage("");
    setSelectedImage("");
    toggle();
    validation.setValues({ name: "", email: "", roleId: "", password: "" });
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Employee" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="employeeList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Employee List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          id="create-btn"
                          onClick={addEmployee}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Employee
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Employee || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Employee..."
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Employee" : "Add Employee"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div>
                              Profile (Optional)
                            </div>
                            <div className="position-absolute  bottom-0 end-0">
                              <Label
                                htmlFor="customer-image-input"
                                className="mb-0"
                              >
                                <div className="avatar-xs cursor-pointer">
                                  <div className="avatar-title bg-light border rounded-circle text-muted">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </Label>
                              <Input
                                name="formImage"
                                className="form-control d-none"
                                id="customer-image-input"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={handleImageChange}
                              />
                            </div>
                            <div className="avatar-lg p-1">
                              <div className="avatar-title bg-light rounded-circle">
                                <img
                                  src={previewImage || dummyImg}
                                  alt="dummyImg"
                                  id="customer-img"
                                  className="avatar-md rounded-circle object-fit-cover"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Employeename-field"
                            className="form-label"
                          >
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            id="employeename-field"
                            className="form-control"
                            placeholder="Enter  Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="status-field"
                            className="form-label"
                          >
                            Role<span className="text-danger">*</span>
                          </Label>

                          <Input
                            name="roleId"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.roleId || ""}
                            invalid={
                              validation.touched.roleId &&
                              validation.errors.roleId
                                ? true
                                : false
                            }
                          >
                            <option>Select a role...</option>
                            {Roles?.map((item: any, index: any) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.roleId &&
                          validation.errors.roleId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.roleId}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Employeename-field"
                            className="form-label"
                          >
                            Phone Number <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="contactNumber"
                            id="employeename-field"
                            className="form-control"
                            placeholder="Enter Phone Number "
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contactNumber || ""}
                            invalid={
                              validation.touched.contactNumber &&
                              validation.errors.contactNumber
                                ? true
                                : false
                            }
                          />
                          {validation.touched.contactNumber &&
                          validation.errors.contactNumber ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contactNumber}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Employeename-field"
                            className="form-label"
                          >
                            Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="email"
                            id="employeename-field"
                            className="form-control"
                            placeholder="Enter Email"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Employeename-field"
                            className="form-label"
                          >
                            Address <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="address"
                            id="employeename-field"
                            className="form-control"
                            placeholder="Enter address"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </div>
                        {!isEdit && (
                          <div className="mb-3">
                            <Label
                              htmlhtmlFor="Employeename-field"
                              className="form-label"
                            >
                              Password<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="password"
                              id="employeename-field"
                              className="form-control"
                              placeholder="Enter Password"
                              type="text"
                              validate={{
                                required: { value: true },
                              }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>

                          <button
                            type="submit"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            disabled={Duplicate}
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add Employee"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the Employee{" "}
              <strong>{EmployeeToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={toggleDeleteModal}
              >
                Cancel
              </Button>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={() => {
                  confirmDelete(EmployeeToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Employee;
