/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";

import TableContainer from "../../Components/Common/TableContainer";
import { listDelivery} from "./_requests";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";

const  Delivery = (data: any) => {
  const { userProfile } = useProfile();
  const [Delivery, setDelivery] = useState<any>([]);
  const [Deliveryaccess, setDeliveryaccess] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  useEffect(() => {
    // getCRUD(DeliveryProfile?.role, Data).then((res: any) => {
    //   setrolecaccess(res.Data[0]);
    // });
  }, []);


  useEffect(() => {
    fetchDelivery();
  }, []);

  const fetchDelivery = async() => {
  const res = await  listDelivery();
  setDelivery(res.data);

  };

  // Deliverys Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
    {
        header: "Driver",
        accessorKey: "driver.name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={cell.row.original?.driver?.profile}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to={`/driver/view/${cell.row.original?.driver?.id}`}
                    className="text-body"
                  >
                    {" "}
                    {cell.getValue()}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
          {
              header: "User",
              accessorKey: "user.name",
              enableColumnFilter: false,
              cell: (cell: any) => (
                <>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-sm bg-light rounded p-1">
                        <img
                          src={cell.row.original?.user?.profile}
                          alt=""
                          className="img-fluid d-block"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="fs-14 mb-1">
                        <Link
                         to={`/user/view/${cell.row.original?.user?.id}`}
                          className="text-body"
                        >
                          {" "}
                          {cell.getValue()}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </>
              ),
            },
      {
        header: "Delivery Date",
        accessorKey: "delivery_date",
        enableColumnFilter: false,
        cell: ({ getValue }: any) => getValue() || "-",
      },
      {
        header: "Delivery Status",
        accessorKey: "order_status.name",
        enableColumnFilter: false,
        cell: ({ getValue }: any) => getValue() || "-",
      }
      
      ,


     {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to={`/delivery/view/${cellProps.row.original.id}`}
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const DeliveryData = cellProps.row.original;
                    // handleDeliveryClick(DeliveryData);
                  }}
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
              {/* <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const DeliveryData = cellProps.row.original;
                    onClickDelete(DeliveryData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
        <Container fluid>
          <BreadCrumb title="Delivery" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="DeliveryList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Delivery List</h5>
                      </div>
                    </div>
         
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Delivery || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Delivery..."
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Delivery;
