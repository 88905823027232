import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Driver_URL = `${API_URL}/driver`;

const listRoute = () => {
  return axios.get(`${API_URL}/custom/list-route`).then((response) => {
    return response;
  });
};
const listDriver = () => {
  return axios.get(`${Driver_URL}/list`).then((response) => {
    return response;
  });
};

const createDriver = (formData: FormData) => {
  return axios.post(`${Driver_URL}/add`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
    },
  }).then((response) => {
    return response;
  });
};


const updateDriver = (formData: FormData, id: any) => {
  return axios.put(`${Driver_URL}/edit/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
    },
  }).then((response) => {
    return response;
  });
};

const viewDriver = (id: any) => {
  return axios.get(`${Driver_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};


const deleteDriver = (id: any, userId: number) => {
  return axios.put(`${Driver_URL}/delete/${id}`, { userId }).then((response) => {
    return response.data;
  });
};


export { listRoute , listDriver, deleteDriver, viewDriver, createDriver, updateDriver };
