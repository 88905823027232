import { useEffect, useState, useMemo, useCallback } from "react";
import { Card, CardBody, Col, Container, CardHeader, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { listActivityLog, listModule } from "./_requests";
import moment from "moment-timezone";

const ActivityLog = () => {
  const [orderList, setOrderList] = useState<any>([]);
  const [modules, setModules] = useState<any>([]);
  const [selectedModule, setSelectedModule] = useState<any>("");

  const handleModule = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModule(e.target.value);
  };

  useEffect(() => {
    const data = {
      moduleId: selectedModule ?? "",
    };
    listActivityLog(data).then((res: any) => {
      setOrderList(res.data);
    });
  }, [selectedModule]);


  useEffect(() => {
    listModule().then((res: any) => {
      setModules(res.data);
    });
  }, []);

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM YYYY");
    return date1;
  };

  const handleValidTime = (time: any) => {
    // Convert the input time to Indian Standard Time (IST)
    const timeInIST = moment.tz(time, "Asia/Kolkata");

    // Get hours and minutes in IST
    const getHour = timeInIST.hours();
    const getMin = timeInIST.minutes();

    // Format time to hh:mm
    const formattedTime = moment()
      .set({ hour: getHour, minute: getMin })
      .format("hh:mm");

    // Determine AM/PM
    const meridiem = getHour >= 12 ? "PM" : "AM";

    // Return the final formatted time in IST
    return `${formattedTime} ${meridiem}`;
  };

  // Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        accessorKey: "s_no",
        cell: (cell: any) => {
          const index = cell.row.index + 1; // Assuming you want the S.No to start from 1
          return <span>{index}</span>;
        },
      },
      {
        header: "User",
        accessorKey: "employee.name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              {/* <Link
                to={`/orders/view/${cell.row.original.id}`}
                className="fw-medium link-primary"
              > */}
              {cell.getValue()}
              {/* </Link> */}
            </>
          );
        },
      },
      {
        header: "Module",
        accessorKey: "module.name",
        enableColumnFilter: false,
      },

      {
        header: "Message",
        accessorKey: "message",
        enableColumnFilter: false,
      },
      {
        header: "Timing",
        accessorKey: "timing",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = cell.row.original.timing;
          return (
            <>
              <div>
                {handleValidDate(date)},
                <small className="text-muted"> {handleValidTime(date)}</small>
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  document.title = " Paal Dappa | Admin panel";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Roles" pageTitle="Activity Log" />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Activity Log</h5>
                  </div>
                  <Col sm="4" className="mb-2">
                    <label htmlFor="modules">Modules</label>
                    <select
                      id="modules"
                      name="modules"
                      className="form-select"
                      value={selectedModule}
                      onChange={handleModule}
                    >
                      <option value="">All</option>
                      {modules.map((item: any) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  {orderList?.length ? (
                    <TableContainer
                      columns={columns}
                      data={orderList || []}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-1 mt-0"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                    />
                  ) : (
                    <p className="fw-bold text-center">No Results Found</p>
                  )}
                </div>

                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ActivityLog;
