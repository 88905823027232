import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Nav, NavItem, Row, TabContent, Table, TabPane, Tooltip, NavLink } from "reactstrap";
import classnames from "classnames";

const ViewPlanSubscription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const details = location.state?.details;

  console.log("details", details);

  const [showMore, setShowMore] = useState(false);
  const toggleReadMore = () => setShowMore(!showMore);
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab: string) => setActiveTab(tab);

  const sanitizeHTML = useCallback((html: string) => {
    return html?.replace(/<script[^>]*>([\S\s]*?)<\/script>/gi, "");
  }, []);

  const sanitizedContent = sanitizeHTML(details?.description || "");

  // If no details, redirect back
  if (!details) {
    navigate("/plan-subscription");
    return null;
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Button color="primary" onClick={() => navigate(-1)}>Go Back</Button>
              <h3 className="text-primary fw-bold">{details.name}</h3>
            </div>

            <Card className="shadow-lg border-0 rounded-3 p-4 position-relative">
                  {/* Edit & Delete Icons */}
                            <div className="position-absolute top-0 end-0 mt-3 me-3">
                              <div className="d-flex gap-2">
                                {/* Edit Button */}
                                <div>
                                  <Tooltip placement="top" target="EditTooltip">
                                    Edit
                                  </Tooltip>
                                  <a href={`/plan-subscription/edit/${id}`} id="EditTooltip" className="btn btn-light">
                                    <i className="ri-pencil-fill align-bottom"></i>
                                  </a>
                                </div>
              
                                {/* Delete Button */}
                                <div>
                                  <Tooltip placement="top" target="DeleteTooltip">
                                    Delete
                                  </Tooltip>
                                  <span id="DeleteTooltip" className="btn btn-light">
                                    <i className="mdi mdi-trash-can-outline align-bottom"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
              <CardBody>
                {/* Tabs */}
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => toggleTab("1")}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => toggleTab("2")}
                    >
                      User List
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab} className="mt-4">
                  {/* Overview Tab */}
                  <TabPane tabId="1">
                    <Row className="gx-lg-5">
                      <Col xl={8}>
                        {details?.image && (
                          <div>
                            <img
                              src={details.image}
                              alt={details.name}
                              width={150}
                              height={100}
                              className="rounded mt-2"
                              style={{ cursor: "zoom-in" }}
                            />
                          </div>
                        )}
                      </Col>

                      <Col xl={4}>
                        <Card className="border-0 shadow-sm rounded p-3 bg-light mt-3">
                          <h5 className="text-secondary">Total Amount</h5>
                          <p className="fw-bold text-dark mb-0">₹{details.total_amount}</p>
                        </Card>
                      </Col>
                    </Row>
    <Row className="mt-4">
                      {/* Features & Description - Equal Size */}
                      <Col xl={6}>
                        <Card className="border-0 shadow-sm rounded p-3 bg-light h-100">
                          <h5 className="text-secondary">Features</h5>
                          <ul className="list-unstyled mb-0">
                            {details?.featuresDetails?.map((feature: any, index: number) => (
                              <li key={index} className="py-1">
                                <i className="mdi mdi-check-circle text-success me-2"></i> {feature.name}
                              </li>
                            ))}
                          </ul>
                        </Card>
                      </Col>

                      <Col xl={6}>
                        <Card className="border-0 shadow-sm rounded p-3 bg-light h-100">
                          <h5 className="text-secondary">Description</h5>
                          <p className="text-muted mb-0">
                            {showMore ? details.description : details.description?.slice(0, 100)}...
                          </p>
                          {details.description?.length > 100 && (
                            <button className="btn btn-link p-0 text-primary" onClick={toggleReadMore}>
                              {showMore ? "Read Less" : "Read More"}
                            </button>
                          )}
                        </Card>
                      </Col>
                    </Row>

                    {/* Variants Table */}
                    <Row className="mt-4">
                      <Col xl={12}>
                        <h5 className="text-secondary">Variants:</h5>
                        <div className="table-responsive">
                          <Table striped bordered className="bg-white rounded">
                            <thead className="table-light">
                              <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Variant</th>
                                <th>Price</th>
                                <th>Validity</th>
                                <th>Slot</th>
                              </tr>
                            </thead>
                            <tbody>
                              {details?.details?.length > 0 ? (
                                details.details.map((detail: any, index: number) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{detail.product?.name || "N/A"}</td>
                                    <td>{detail.variant_value} {detail.variant?.name || ""}</td>
                                    <td>₹{detail.gross_amount}</td>
                                    <td>{detail.validity} {detail.validity_type}</td>
                                    <td>{detail.slot_type_name}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    No variants available.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>

                  {/* User List Tab */}
                  <TabPane tabId="2">
                    <h5>User List</h5>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ViewPlanSubscription;
