import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ModalFooter,
  Modal,
  Form,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  CardHeader,
  Button,
} from "reactstrap";
import { PencilSquare } from 'react-bootstrap-icons';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import { updateEmployee, viewEmployee } from "../../pages/Employee/_requests";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const navigate= useNavigate();
  const [selectedImage, setSelectedImage] = useState<any>();
  const [previewImage, setPreviewImage] = useState<string | null>(null); // Optional for preview
  const [modal, setModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<any>();
  const [Employee, setEmployee] = useState<any>([]);
  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];

      // Store the file object in state
      setSelectedImage(file);

      // Optional: Generate a base64 preview (for image preview purposes)
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setPreviewImage(e.target.result as string); // Store the base64 string for preview
        }
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("authUser");

    if (storedUser) {
      // Parse storedUser to get the object
       const parsedUser = JSON.parse(storedUser);

      // Check if parsedUser has a userId property and call viewEmployee
      if (parsedUser?.userId) {
        setUserId(parsedUser.userId);
        fetchEmployee(parsedUser.userId);
      } else {
        console.error("UserId not found in storedUser");
      }
    }
  }, []);

  const fetchEmployee=(userId:any)=>{
    viewEmployee(userId).then((res: any) => {
      setEmployee(res);
    });
  }

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: Employee.name,
      contactNumber: Employee.contactNumber,
      email: Employee.email,
      address: Employee.address,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      contactNumber: Yup.string().required("Phone Number is required"),
      email: Yup.string().required("Email is required"),
      address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values: any) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("contactNumber", values.contactNumber);
        formData.append("address", values.address);

        if (selectedImage) {
          formData.append("profileNew", selectedImage);
        }
      updateEmployee(formData, Employee.id).then((res:any)=>{
        if(res?.status === true){
          toast.success(res?.message);
          fetchEmployee(userId);
          toggle();
         }
         else{
          toast.error(res?.message, { autoClose: 3000 });
        }
      })
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong", { autoClose: 3000 });
      }
    },
  });

  document.title = "Paal Dappa | Admin panel | Profile ";
  return (
    <React.Fragment>
      <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between py-3 px-4">
                  <h5 className="mb-0">User Details</h5>
                  <Button color="primary" size="l" onClick={()=>{toggle(); setPreviewImage(Employee?.profile);}}>
                    <PencilSquare className="me-2"/> Edit 
                  </Button>
                </CardHeader>
                <CardBody>
                  <Row className="mb-3">
                    <Col className="col-4">Profile</Col>
                    <Col className="col-3">
                      <img
                        className="avatar-lg"
                        src={Employee?.profile}
                        alt="user-profile"
                      ></img>{" "}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-4">Name</Col>
                    <Col className="col-3">{Employee?.name} </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-4">Role</Col>
                    <Col className="col-3">{Employee?.role?.name}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-4">Employee Id</Col>
                    <Col className="col-3">{Employee?.employeeId}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-4">Phone Number</Col>
                    <Col className="col-3">{Employee?.contactNumber}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-4">Email</Col>
                    <Col className="col-3">{Employee?.email}</Col>
                  </Row>
                  <Row className="mb-3">
                    <Col className="col-4">Address</Col>
                    <Col className="col-3">{Employee.address}</Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
            <ModalHeader className="bg-light p-3" toggle={toggle}>
              {"Edit Employee"}
            </ModalHeader>
            <Form
              className="tablelist-form"
              onSubmit={(e: any) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <ModalBody>
                <input type="hidden" id="id-field" />

                <div className="text-center">
                  <div className="position-relative d-inline-block">
                    <div>Profile (Optional)</div>
                    <div className="position-absolute  bottom-0 end-0">
                      <Label htmlFor="customer-image-input" className="mb-0">
                        <div className="avatar-xs cursor-pointer">
                          <div className="avatar-title bg-light border rounded-circle text-muted">
                            <i className="ri-image-fill"></i>
                          </div>
                        </div>
                      </Label>
                      <Input
                        name="formImage"
                        className="form-control d-none"
                        id="customer-image-input"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleImageChange}
                      />
                    </div>
                    <div className="avatar-lg p-1">
                      <div className="avatar-title bg-light rounded-circle">
                        <img
                          src={previewImage || dummyImg}
                          alt="dummyImg"
                          id="customer-img"
                          className="avatar-md rounded-circle object-fit-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <Label
                    htmlhtmlFor="Employeename-field"
                    className="form-label"
                  >
                    Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="name"
                    id="employeename-field"
                    className="form-control"
                    placeholder="Enter  Name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label
                    htmlhtmlFor="Employeename-field"
                    className="form-label"
                  >
                    Phone Number <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="contactNumber"
                    id="employeename-field"
                    className="form-control"
                    placeholder="Enter Phone Number "
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.contactNumber || ""}
                    invalid={
                      validation.touched.contactNumber &&
                      validation.errors.contactNumber
                        ? true
                        : false
                    }
                  />
                  {validation.touched.contactNumber &&
                  validation.errors.contactNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.contactNumber}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label
                    htmlhtmlFor="Employeename-field"
                    className="form-label"
                  >
                    Email <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="email"
                    id="employeename-field"
                    className="form-control"
                    placeholder="Enter Email"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label
                    htmlhtmlFor="Employeename-field"
                    className="form-label"
                  >
                    Address <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="address"
                    id="employeename-field"
                    className="form-control"
                    placeholder="Enter address"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.address || ""}
                    invalid={
                      validation.touched.address && validation.errors.address
                        ? true
                        : false
                    }
                  />
                  {validation.touched.address && validation.errors.address ? (
                    <FormFeedback type="invalid">
                      {validation.errors.address}
                    </FormFeedback>
                  ) : null}
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn text-white add-btn me-1"
                    style={{
                      backgroundColor: "#28a8df",
                      borderColor: "#28a8df",
                    }}
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    {" "}
                    Close{" "}
                  </button>

                  <button
                    type="submit"
                    className="btn text-white add-btn me-1"
                    style={{
                      backgroundColor: "#28a8df",
                      borderColor: "#28a8df",
                    }}
                  >
                    {"Update"}
                  </button>
                </div>
              </ModalFooter>
            </Form>
            <ToastContainer closeButton={false} limit={1} />
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
