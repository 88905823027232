import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const Store_URL = `${API_URL}/store`;

const ListStore = () => {
  return axios.get(`${Store_URL}/list`).then(
      (response) => response 
    )
    .catch((error) => {
      console.error("Error fetching data:", error);
      throw error;
    });
};

const createStore = (formData: FormData) => {
  return axios
    .post(`${Store_URL}/add`, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
      },
    })
    .then((response) => response.data);
};

const deleteStoreById = (id: any, userId: any) => {
  return axios.put(`${Store_URL}/delete/${id}`, { userId }).then((response) => {
    return response.data;
  });
};

const updateStore = (formData: FormData, id: any) => {
  return axios
    .put(`${Store_URL}/edit/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
      },
    })
    .then((response) => {
      return response.data;
    });
};

export { ListStore, createStore, deleteStoreById, updateStore };
