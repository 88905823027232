/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import TableContainer from "../../Components/Common/TableContainer";
import {
  checkDuplicate,
  createBrand,
  deleteBrand,
  listBrand,
  updateBrand,
} from "./_requests";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";

const Brand = (data: any) => {
  const { userProfile } = useProfile();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [Brand, setBrand] = useState<any>([]);
  const [brandaccess, setBrandcaccess] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [BrandToDeleteId, setBrandToDeleteId] = useState<string>("");
  const [BrandToDeleteName, setBrandToDeleteName] = useState<string>("");
  const [Duplicate, setDuplicate] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<any>();
  const [Email, setEmail] = useState("");
  const toggle = useCallback(() => {
    setModal(!modal);
    setDuplicate("");
  }, [modal]);

  // useEffect(() => {
  //   getCRUD(userProfile?.role, Data).then((res: any) => {
  //     setrolecaccess(res.Data[0]);
  //   });
  // }, []);

  const [previewImage, setPreviewImage] = useState<string | null>(null); // Optional for preview
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];

      // Store the file object in state
      setSelectedImage(file);

      // Optional: Generate a base64 preview (for image preview purposes)
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setPreviewImage(e.target.result as string); // Store the base64 string for preview
        }
      };
      reader.readAsDataURL(file);
    }
  };

  
  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    fetchBrand();
  }, []);

  const fetchBrand =async () => {
   const res= await listBrand()
    setBrand(res.data);
  };

  // Delete Data
  const onClickDelete = useCallback(
    (Brand: any) => {
      setBrandToDeleteName(Brand.name);
      setBrandToDeleteId(Brand.id);
      setBrandcaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.remove === 1) {
          toggleDeleteModal();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [setBrandToDeleteName, setBrandToDeleteId, toggleDeleteModal]
  );

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name:  "",
      icon:  ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: (values: any) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        if (selectedImage) {
          formData.append(isEdit ? "iconNew" : "icon", selectedImage);
        }
        formData.append(isEdit ? "updated_by" : "created_by", userProfile?.userId);
    
        const actionPromise = isEdit ? updateBrand(formData, values.id) : createBrand(formData);
    
        actionPromise.then((res: any) => {
          toast.success(res.message, { autoClose: 3000 });
          fetchBrand(); // Fetch latest data after successful update/create
          validation.resetForm();
          toggle();
        }).catch((error: any) => {
          console.error("Error:", error);
          toast.error("Something went wrong", { autoClose: 3000 });
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong", { autoClose: 3000 });
      }
    }
  });

  const confirmDelete = async(BrandId: any) => {
    await deleteBrand(BrandId , userProfile?.userId).then((res:any) => {
      toast.success(res.message, { autoClose: 3000 });
    });
    toggleDeleteModal();
    fetchBrand();
  };

  // Update Data
  const handleBrandClick = useCallback(
    (Brand: any) => {
      validation.setValues({
        id: Brand.id,
        name: Brand.name
      });
      setPreviewImage(Brand.icon);
      setIsEdit(true);

      setBrandcaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.edit === 1) {
          toggle();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [toggle, validation]
  );

  const DuplicateCheck = (val: any) => {
    try {
      checkDuplicate(val).then((response: any) => {
        if (isEdit) {
          if (response?.isDuplicate === true && Email !== val) {
            setDuplicate("Email already exists");
          } else {
            setDuplicate("");
          }
        } else {
          if (response?.isDuplicate === true) {
            setDuplicate("Email already exist");
          } else {
            setDuplicate("");
          }
        }
      });
    } catch (error) {
      console.error("Error checking for duplicate role:", error);
    }
  };

  // Brands Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Brand Logo",
        accessorKey: "icon",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={cell.row.original.icon}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        header: "Brand Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const BrandData = cellProps.row.original;
                    handleBrandClick(BrandData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const BrandData = cellProps.row.original;
                    onClickDelete(BrandData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleBrandClick, onClickDelete]
  );

  const addBrand = () => {
    // if (Brandaccess?.add === 1) {
      setIsEdit(false);
      toggle();
      validation.setValues({ name: "",});
      setPreviewImage("");
      setSelectedImage("");
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Brand" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="BrandList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Brand List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          id="create-btn"
                          onClick={addBrand}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Brand
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Brand || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Brand..."
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Brand" : "Add Brand"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div className="text-center">
                      <div className="position-relative d-inline-block">
                        <div>Brand Logo <span className="text-danger">*</span></div>
                        <div className="position-absolute  bottom-0 end-0">
                          <Label
                            htmlFor="customer-image-input"
                            className="mb-0"
                          >
                            <div className="avatar-xs cursor-pointer">
                              <div className="avatar-title bg-light border rounded-circle text-muted">
                                <i className="ri-image-fill"></i>
                              </div>
                            </div>
                          </Label>
                          <Input
                            name="formImage"
                            className="form-control d-none"
                            id="customer-image-input"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleImageChange}
                          />
                        </div>
                        <div
                          className="avatar-lg p-1"
                        >
                          <div className="avatar-title bg-light rounded-circle">
                            <img
                              src={previewImage || dummyImg}
                              alt="dummyImg"
                              id="customer-img"
                              className="avatar-md rounded-circle object-fit-cover"
                            />
                          </div>
                        </div>
                      </div>
                     
                    </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Brandname-field"
                            className="form-label"
                          >
                            Brand Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            id="Brandname-field"
                            className="form-control"
                            placeholder="Enter Brand Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>

                 
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>

                          <button
                            type="submit"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            disabled={Duplicate}
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add Brand"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the Brand{" "}
              <strong>{BrandToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={() => {
                  confirmDelete(BrandToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Brand;
