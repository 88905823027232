import axios from "axios";
const API_URL = `${process.env.REACT_APP_API_URL}/order`;
const CUSTOM_URL = `${process.env.REACT_APP_API_URL}/custom`;

const listOrder = (user_id?: any) => {
  return axios.post(`${API_URL}/list`,{user_id}).then((response) => {
    return response;
  });
};

const viewOrder = (id: any) => {
  return axios.get(`${API_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deleteOrder = (id: any) => {
  return axios.put(`${API_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const OrderStatus = () => {
  return axios.get(`${CUSTOM_URL}/list-order-status`).then((response) => {
    return response;
  });
};
const UpdateOrderStatus = (data: any) => {
  return axios.post(`${API_URL}/update-order-status`,{data}).then((response) => {
    return response;
  });
};

export { listOrder, viewOrder, deleteOrder, OrderStatus , UpdateOrderStatus};
