import axios from "axios";
const API_URL = `${process.env.REACT_APP_API_URL}/settings`;

const listSettings = (data?:any) => {
  return axios.get(`${API_URL}/list`,data).then((response) => {
    return response.data;
  });
};

const createSettings = (data: any) => {
  return axios.post(`${API_URL}/add`,data).then((response) => {
    return response;
  });
};

const updateSettings = (id :any ,data: any) => {
  return axios.put(`${API_URL}/edit/${id}`,data).then((response) => {
    return response;
  });
};

const viewSettings = (id: any) => {
  return axios.get(`${API_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};

const deleteSettings = (id: any) => {
  return axios.put(`${API_URL}/delete/${id}`).then((response) => {
    return response.data;
  });
};

export { listSettings, createSettings , updateSettings , viewSettings , deleteSettings };
