import { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Collapse,
  Button,
  ModalFooter,
  ModalBody,
  Modal,
  ModalHeader,
  Label,
} from "reactstrap";
import "./order.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import EcommerceOrderProduct from "./OrderProduct";
import { OrderStatus, UpdateOrderStatus, viewOrder } from "./_requests";
import OrderTracking from "./OrderTracking";
import { useToast } from "../../Components/Common/ToastContext";
import { toast, ToastContainer } from "react-toastify";
import OrderStatusModal from "./OrderStatusModal";
const OrderDetail = (props: any) => {
  const { id } = useParams();
  const { showToast, toaster, clearToast } = useToast();
  const [order, setOrder] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [orderStatusLists, setStatus] = useState<{ id: string; name: string }[]>([]);
  const history = useNavigate();
  useEffect(() => {
    if (toaster?.message) {
      if (toaster.status === true) {
        toast.success(toaster.message, { autoClose: 3000 });
      } else {
        toast.error(toaster.message, { autoClose: 3000 });
      }
      clearToast();
    }
  }, [toaster, clearToast]);

  useEffect(() => {
    viewOrders();

    OrderStatus().then((res: any) => {
      setStatus(res.data);
    });
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const viewOrders = useCallback(() => {
    viewOrder(id).then((res: any) => {
      setOrder(res.data);
    });
  }, [id]);

  const handleStatus = async () => {
    const data = { order_id: id, tracking_status: selectedStatus };
    console.log("data", data);
    const res: any = await UpdateOrderStatus(data);
    if (res.status === true) {
      showToast(res.message, res.status);
      viewOrders();
    } else {
      showToast(res.message, res.status);
    }
    toggleModal();
  };
  function goBack() {
    history(-1);
  }
  document.title = "Paal Dappa | Order Details";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Order Details" pageTitle="Ecommerce" />
        <ToastContainer closeButton={false} limit={1} />
        <Row>
          <Col>
            {" "}
            <button
              onClick={goBack}
              className="btn btn-primary px-2 text-center mb-3"
            >
              <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
            </button>{" "}
          </Col>
        </Row>
        <Row>
          <Col xl={9}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5 className="card-title flex-grow-1 mb-0">{order?.order_code_id}</h5>
                  <div className="flex-shrink-0">
                    <Link to="/apps-invoices-details" className="btn btn-success btn-sm">
                      <i className="ri-download-2-fill align-middle me-1"></i> Invoice
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="table-responsive table-card">
                  <table className="table table-nowrap align-middle table-borderless mb-0">
                    <thead className="table-light text-muted">
                      <tr>
                        <th scope="col" className="w-40">Product Details</th>
                        <th scope="col" className="w-20">Item Price</th>
                        <th scope="col" className="w-20">Quantity</th>
                        <th scope="col" className="text-end w-20">Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.orderItems?.map((product: any) => (
                        <EcommerceOrderProduct product={product} key={product.id} />
                      ))}
                      <tr className="border-top border-top-dashed">
                        <td colSpan={3}></td>
                        <td colSpan={2} className="fw-medium p-0">
                          <table className="table table-borderless mb-0">
                            <tbody>
                              <tr>
                                <td className="w-50">Sub Total :</td>
                                <td className="text-end w-50">{order?.sub_total_amount}</td>
                              </tr>
                              <tr>
                                <td className="w-50">Discount :</td>
                                <td className="text-end w-50">-{order?.discount_amount}</td>
                              </tr>
                              <tr>
                                <td className="w-50">Shipping Charge :</td>
                                <td className="text-end w-50">{order?.shipping_charge}</td>
                              </tr>
                              <tr>
                                <td className="w-50">Estimated Tax :</td>
                                <td className="text-end w-50">{order?.tax_amount}</td>
                              </tr>
                              <tr className="border-top border-top-dashed">
                                <th scope="row" className="w-50">Total :</th>
                                <th className="text-end w-50">{order?.total_amount}</th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-sm-flex align-items-center">
                  <h5 className="card-title flex-grow-1 mb-0">Order Status</h5>
                  <div className="flex-shrink-0 mt-2 mt-sm-0">
                    <div
                      className="btn btn-soft-info btn-sm mt-2 mt-sm-0"
                      onClick={toggleModal}
                    >
                      <i className="ri-map-pin-line align-middle me-1"></i>{" "}
                      Order Status update
                    </div>{" "}
                    {/* <Link
                      to="#"
                      className="btn btn-soft-info btn-sm mt-2 mt-sm-0"
                    >
                      <i className="ri-map-pin-line align-middle me-1"></i>{" "}
                      Change Address
                    </Link>{" "}
                    <Link
                      to="#"
                      className="btn btn-soft-danger btn-sm mt-2 mt-sm-0"
                    >
                      <i className="mdi mdi-archive-remove-outline align-middle me-1"></i>{" "}
                      Cancel Order
                    </Link> */}
                  </div>
                </div>
              </CardHeader>
              <OrderTracking orderTracking={order?.orderTracking} />
            </Card>
          </Col>
          <Col xl={3}>
            <Card>
              <CardHeader>
                <div className="d-flex">
                  <h5 className="card-title flex-grow-1 mb-0">Customer Details</h5>
                  <div className="flex-shrink-0">
                    <Link to={`/user/view/${order?.users?.id}`} className="link-secondary">
                      View Profile
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled mb-0 vstack gap-3">
                  <li>
                    <div className="d-flex align-items-center">
                      <img
                        src={order?.users?.profile}
                        alt=""
                        className="avatar-sm rounded-circle me-3"
                      />
                      <div>
                        <h6 className="mb-1">{order?.users?.name}</h6>
                        <p className="text-muted mb-0">{order?.users?.mobile_number}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <h6 className="mb-1">Shipping Address:</h6>
                    <p className="text-muted mb-0">{order?.shipping_address}</p>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Order Status Modal */}
        <OrderStatusModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          orderStatusLists={orderStatusLists}
          handleStatus={handleStatus}
        />
      </Container>
    </div>
  );
};

export default OrderDetail;
