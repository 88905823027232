/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";

import TableContainer from "../../Components/Common/TableContainer";
import { listSubscription} from "./_requests";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";

const Subscription = (data: any) => {
  const { userProfile } = useProfile();
  const [subscription, setSubscription] = useState<any>([]);
  const [Subscriptionaccess, setSubscriptionaccess] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  useEffect(() => {
    // getCRUD(SubscriptionProfile?.role, Data).then((res: any) => {
    //   setrolecaccess(res.Data[0]);
    // });
  }, []);


  useEffect(() => {
    fetchSubscription();
  }, []);

  const fetchSubscription = () => {
    listSubscription().then((res: any) => {
      setSubscription(res.data);
    });
  };

  // Subscriptions Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "User",
        accessorKey: "user.name",
        enableColumnFilter: false,
        cell: ({ getValue }: any) => getValue() || "-",
      },
      {
        header: "plan",
        accessorKey: "plan.name",
        enableColumnFilter: false,
        cell: ({ getValue }: any) => getValue() || "-",
      },

      {
        header: "Start Date",
        accessorKey: "start_date",
        enableColumnFilter: false,
        cell: ({ getValue }: any) => getValue() || "-",
      },
      {
        header: "End Date",
        accessorKey: "end_date",
        enableColumnFilter: false,
        cell: ({ getValue }: any) => getValue() || "-",
      },
      {
        header: "Active Status",
        accessorKey: "is_active",
        enableColumnFilter: false,
        cell: ({ row }: { row: { original: { is_active: boolean | null } } }) => {
          const isActive = row.original.is_active;
          if (isActive === true) {
            return <span className="badge bg-success">Active</span>;
          } else if (isActive === false) {
            return <span className="badge bg-danger">Expired</span>;
          } else {
            return <span className="badge bg-secondary">-</span>;
          }
        },
      }
      
      ,


     {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to={`/subscription/view/${cellProps.row.original.id}`}
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const SubscriptionData = cellProps.row.original;
                    // handleSubscriptionClick(SubscriptionData);
                  }}
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
              {/* <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const SubscriptionData = cellProps.row.original;
                    onClickDelete(SubscriptionData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
        <Container fluid>
          <BreadCrumb title="Subscription" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="SubscriptionList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Subscription List</h5>
                      </div>
                    </div>
         
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={subscription || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Subscription..."
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Subscription;
