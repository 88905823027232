import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { getDelivery } from "./_requests";

export function ViewDelivery() {
  const history = useNavigate();
  const { id } = useParams();
  const [Delivery, setDelivery] = useState<any>([]);
  useEffect(() => {
    getDelivery(id).then((res: any) => {
      setDelivery(res.data);
    });
  }, []);

  document.title = " Paal Dappa | Admin panel";
  function goBack() {
    history(-1);
  }

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <ToastContainer closeButton={false} limit={1} />
            {/* <BreadCrumb title=" View Plan " pageTitle="Plan" /> */}
            <Row>
              <Col>
                {" "}
                <button
                  onClick={goBack}
                  className="btn btn-primary px-2 text-center mb-3"
                >
                  <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
                </button>{" "}
              </Col>
            </Row>
            <Card>
              <CardHeader>Delivery Details</CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-4">Driver Name</Col>
                  <Col className="col-3">{Delivery?.driver?.name ?? "-"}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">User Name</Col>
                  <Col className="col-3">{Delivery?.user?.name ?? "-"}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Delivery Address</Col>
                  <Col className="col-8">
                    {Delivery?.address_book ? (
                      <div>
                        <div>
                          {/* <strong>Address:</strong>{" "} */}
                          {Delivery.address_book?.address ?? "-"}
                        </div>
                        <div>
                          {/* <strong>City:</strong>{" "} */}
                          {Delivery.address_book?.city ?? "-"}
                        </div>
                        <div>
                          {/* <strong>State:</strong>{" "} */}
                          {Delivery.address_book?.state ?? "-"}
                        </div>
                        <div>
                          {/* <strong>Postal Code:</strong>{" "} */}
                          {Delivery.address_book?.postal_code ?? "-"}
                        </div>
                        <div>
                          {/* <strong>Country:</strong>{" "} */}
                          {Delivery.address_book?.country ?? "-"}
                        </div>
                        <div>
                          {/* <strong>Landmark:</strong>{" "} */}
                          {Delivery.address_book?.land_mark ?? "-"}
                        </div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Delivery Date</Col>
                  <Col className="col-3">{Delivery?.delivery_date ?? "-"}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Delivery Status</Col>
                  <Col className="col-3">
                    {Delivery?.order_status?.name ?? "-"}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
