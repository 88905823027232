/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";

import TableContainer from "../../Components/Common/TableContainer";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { checkDuplicate, createFeatures, deleteFeatures, listFeatures, updateFeatures } from "./_requests";

const Features = (data: any) => {
  const { userProfile } = useProfile();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [Features, setFeatures] = useState<any>([]);
  const [Roles, setRoles] = useState<any>([]);
  const [Featuresaccess, setrolecaccess] = useState<any>([]);

  const [modal, setModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [FeaturesToDeleteId, setFeaturesToDeleteId] = useState<string>("");
  const [FeaturesToDeleteName, setFeaturesToDeleteName] = useState<string>("");
  const [Duplicate, setDuplicate] = useState<any>("");
  const [Email, setEmail] = useState("");
  const toggle = useCallback(() => {
    setModal(!modal);
    setDuplicate("");
  }, [modal]);

  // useEffect(() => {


  //   getCRUD(FeaturesProfile?.role, Data).then((res: any) => {
  //     setrolecaccess(res.Data[0]);
  //   });
  // }, []);

  


  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    fetchFeatures();
  }, []);

  const fetchFeatures = () => {
    listFeatures().then((res: any) => {
      setFeatures(res.data);
    });
  };


  // Delete Data
  const onClickDelete = useCallback(
    (Features: any) => {
      setFeaturesToDeleteName(Features.name);
      setFeaturesToDeleteId(Features.id);

      setrolecaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.remove === 1) {
          toggleDeleteModal();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [setFeaturesToDeleteName, setFeaturesToDeleteId, toggleDeleteModal]
  );

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: "",
      featuer_id: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required")
    }),
    onSubmit: async (values: any) => {
      const userId = userProfile?.userId; // Replace this with dynamic user ID if available (e.g., from auth context)

      const data = {
        name: values.name,
        featuer_id: values.featuer_id,
        ...(isEdit ? { updated_by: userId } : { created_by: userId }), // Dynamically add created_by or updated_by
      };

      try {
        if (isEdit) {
          await updateFeatures(data, values.id).then((res:any)=>{
            toast.success(res.message, { autoClose: 3000 });
          })
        } else {
          await createFeatures(data).then((res:any)=>{
            toast.success(res.message, { autoClose: 3000 });
          })
        }
        fetchFeatures();
        validation.resetForm(); // Reset form after success
      } catch (error) {
        toast.error("An error occurred, please try again.", {
          autoClose: 3000,
        });
      }

      toggle(); // Close modal
    },
  });

  const confirmDelete = async(FeaturesId: any) => {
    const res:any= await deleteFeatures(FeaturesId); // Await the delete operation
    toast.success(res?.message, { autoClose: 3000 });
    toggleDeleteModal();
    fetchFeatures();
  };

  // Update Data
  const handleFeaturesClick = useCallback(
    (Features: any) => {
      validation.setValues({
        id: Features.id,
        name: Features.name,
        featuer_id: Features.featuer_id,

      });
      setIsEdit(true);

      setrolecaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.edit === 1) {
          toggle();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [toggle, validation]
  );

  const DuplicateCheck = (val: any) => {
    try {

      checkDuplicate(val).then((response: any) => {
        if (isEdit) {
          if (response?.isDuplicate === true && Email !== val) {
            setDuplicate("Email already exists");
          } else {
            setDuplicate("");
          }
        } else {
          if (response?.isDuplicate === true) {
            setDuplicate("Email already exist");
          } else {
            setDuplicate("");
          }
        }
      });
    } catch (error) {
      console.error("Error checking for duplicate role:", error);
    }
  };

  // Featuress Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Features Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Parent Features",
        accessorKey: "parentFeatuer.name",
        enableColumnFilter: false,
        cell: ({ getValue } :any) => getValue() ? getValue() : "-", // Custom cell rendering
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const FeaturesData = cellProps.row.original;
                    handleFeaturesClick(FeaturesData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const FeaturesData = cellProps.row.original;
                    onClickDelete(FeaturesData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleFeaturesClick, onClickDelete]
  );

  const addFeatures = () => {
    // if (Featuresaccess?.add === 1) {
      setIsEdit(false);
      toggle();
      validation.setValues({ name: "", featuer_id: "" });
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Features" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="FeaturesList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Features List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          id="create-btn"
                          onClick={addFeatures}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Features
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Features || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Features..."
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Features" : "Add Features"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <Label htmlhtmlFor="id-field1" className="form-label">
                            ID
                          </Label>
                          <Input
                            type="text"
                            id="id-field1"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Featuresname-field"
                            className="form-label"
                          >
                            Features Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            id="Featuresname-field"
                            className="form-control"
                            placeholder="Enter Features Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="status-field"
                            className="form-label"
                          >
                          Parent  Features(Optional)
                          </Label>

                          <Input
                            name="featuer_id"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.featuer_id || ""}
                          >
                            <option>Select a parent  Features...</option>
                            {Features?.map((item: any, index: any) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>

                          <button
                            type="submit"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            disabled={Duplicate}
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add Features"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the Features{" "}
              <strong>{FeaturesToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={toggleDeleteModal}>
                Cancel
              </Button>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={() => {
                  confirmDelete(FeaturesToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Features;
