/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,

  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
// Formik


//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import { deleteUser, listUser } from "./_requests";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";

const User = (data: any) => {
  const [User, setUser] = useState<any>([]);
  const [Useraccess, setrolecaccess] = useState<any>([]);
  const { userProfile } = useProfile();
  const [modal, setModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [UserToDeleteId, setUserToDeleteId] = useState<string>("");
  const [UserToDeleteName, setUserToDeleteName] = useState<string>("");
  const [Duplicate, setDuplicate] = useState<any>("");
  const toggle = useCallback(() => {
    setModal(!modal);
    setDuplicate("");
  }, [modal]);

  useEffect(() => {
    // getCRUD(userProfile?.role).then((res: any) => {
    //   setrolecaccess(res.Data[0]);
    // });
  }, []);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = () => {
    listUser().then((res: any) => {
      setUser(res);
    });
  };

  // Delete Data
  const onClickDelete = useCallback(
    (User: any) => {
      setUserToDeleteName(User.name);
      setUserToDeleteId(User.id);

      setrolecaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.remove === 1) {
        toggleDeleteModal();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [setUserToDeleteName, setUserToDeleteId, toggleDeleteModal]
  );

  const confirmDelete = async (UserId: any) => {
    await deleteUser(UserId);
    toggleDeleteModal();
    toast.success("User deleted Successfully", { autoClose: 3000 });
    fetchUser();
  };

  // Users Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={cell.row.original.profile}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to={`/user/view/${cell.row.original.id}`}
                    className="text-body"
                  >
                    {" "}
                    {cell.getValue()}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        header: "Phone No",
        accessorKey: "mobile_number",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              {cell.getValue() || "-"}
            </div>
          </>
        ),
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              {cell.getValue() || "-"}
            </div>
          </>
        ),
      },
      {
        header: "State",
        accessorKey: "state",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              {cell.getValue() || "-"}
            </div>
          </>
        ),
      },
      {
        header: "Pincode",
        accessorKey: "postal_code",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              {cell.getValue() || "-"}
            </div>
          </>
        ),
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to={`/user/view/${cellProps.row.original.id}`}
                  className="text-primary d-inline-block edit-item-btn"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const UserData = cellProps.row.original;
                    onClickDelete(UserData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [onClickDelete]
  );

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="User" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="UserList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">User List</h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={User || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search User..."
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the User{" "}
              <strong>{UserToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={toggleDeleteModal}
              >
                Cancel
              </Button>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={() => {
                  confirmDelete(UserToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default User;
