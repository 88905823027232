import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Label,
  FormFeedback,
  Form,
  Button,
  Table,
} from "reactstrap";
import { useFormik, useFormikContext, FieldArray } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editPlanSubscription, listFeatuer, listSpeacification, listVariant, viewPlanSubscription } from "./_requests";
import classnames from 'classnames';
import { useProfile } from "../../Components/Hooks/UserHooks";
import { useToast } from "../../Components/Common/ToastContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditPlanSubscription = (props: any) => {
  document.title = " Paal Dappa | Admin panel";
  const { userProfile } = useProfile();
  const { showToast } = useToast();
  const history = useNavigate();
  const { id } = useParams(); // Use useParams to get the ID from route params
  console.log("id useParams state", id)
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      type: "1",
      plan_feature_id: "",
      image: null, // Ensure image is included in initialValues
      description: "",
      details: [
        {
          product_id: "",
          variant_id: "",
          validity: "",
          validity_type: "",
          actual_amount: "",
          offer_amount: "",
          gross_amount: "",
          slot_type: "",
          status: "",
        },
      ],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter a Plan Name"),
      type: Yup.string().required("Please Select Plan Type"),
      plan_feature_id: Yup.string().required("Please Select Plan Features"),
      description: Yup.string().required("Please Enter Description"),
      details: Yup.array().of(
        Yup.object().shape({
          product_id: Yup.string().required("Please Enter Product ID"),
          variant_id: Yup.string().required("Please Enter Variant ID"),
          validity: Yup.string().required("Please Enter Validity"),
          validity_type: Yup.string().required("Please Enter Validity Type"),
          actual_amount: Yup.string().required("Please Enter Actual Amount"),
          offer_amount: Yup.string().required("Please Enter Offer Amount"),
          gross_amount: Yup.string().required("Please Enter Gross Amount"),
          slot_type: Yup.string().required("Please Enter Slot Type"),
          status: Yup.string().required("Please Enter Status"),
        })
      ),
    }),
    onSubmit: async (values) => {
      console.log("Form Values:", values);

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("type", String(values.type)); // Ensure type is a string
      formData.append("plan_feature_id", String(values.plan_feature_id)); // Ensure plan_feature_id is a string
      formData.append("description", values.description);
      if (values.image) {
        formData.append("image", values.image || "default.png"); // Ensure image is appended to formData
      }
      formData.append("details", JSON.stringify(values.details.map(detail => ({
        ...detail,
        product_id: Number(detail.product_id), // Ensure product_id is a string
        variant_id: Number(detail.variant_id), // Ensure variant_id is a string
        validity: Number(detail.validity), // Ensure validity is a string
        validity_type: String(detail.validity_type), // Ensure validity_type is a string
        actual_amount: Number(detail.actual_amount), // Ensure actual_amount is a number
        offer_amount: Number(detail.offer_amount), // Ensure offer_amount is a number
        gross_amount: Number(detail.gross_amount), // Ensure gross_amount is a number
        slot_type: String(detail.slot_type), // Ensure slot_type is a string
        // status: Number(detail.status) // Ensure status is a string
      }))));

      console.log("FormData:", formData);

      try {
        const res: any = await editPlanSubscription(id,formData);
        console.log("API Response: editPlanSubscription", res);
        console.log("res", res)
        console.log("res.data", res.data)
        console.log("res.status == true", res.status == true)
        if (res.status === true) {
        toast.success(res.message, res.status);
          validation.resetForm();
          history("/plan-subscription");
        } else {
         toast.error(res.message, res.status);
        }
      } catch (error) {
        console.error("Error in form submission:", error);
      }
    },
  });

  const fetchInitialValues = async (id: string) => {
    try {
      const response = await viewPlanSubscription(id); // Replace with your API call
      const data = response.data;

      validation.setValues({
        name: data.name,
        type: data.type,
        plan_feature_id: data.plan_feature_id,
        image: data.image,
        description: data.description,
        details: data.details.map((detail: any) => ({
          product_id: detail.product_id,
          variant_id: detail.variant_id,
          validity: detail.validity,
          validity_type: detail.validity_type,
          actual_amount: detail.actual_amount,
          offer_amount: detail.offer_amount,
          gross_amount: detail.gross_amount,
          slot_type: detail.slot_type,
          status: detail.status,
        })),
      });
      setPreviewImage(data.image); // Set the preview image
    } catch (error) {
      console.error("Error fetching initial values:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInitialValues(id);
    }
  }, [id]);


  useEffect(() => {
    listSpeacification().then((res: any) => {
      setAvailableSpecification(res.data);
    });
    listVariant().then((res: any) => {
      setAvailableVariants(res.data);
    });
    listFeatuer().then((res: any) => {
      setAvailableFeature(res.data);
    });
  }, []);

  const [customActiveTab, setcustomActiveTab] = useState<any>("1");
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [availableFeature, setAvailableFeature] = useState<any[]>([]);
  const [availableVariants, setAvailableVariants] = useState<any[]>([]);
  const [availableSpecification, setAvailableSpecification] = useState<any[]>([]);
  console.log("availableFeature", availableFeature)
  const [variantData, setVariantData] = useState([
    { product_id: "", variant_id: "", validity: "", validity_type: "", actual_amount: "", offer_amount: "", gross_amount: "", slot_type: "", status: "" }
  ]);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];

      if (file.size > 102400) {
        // Check if file size is greater than 100KB
        validation.setFieldError("image", "File size must be less than 100KB");
        return;
      }

      setSelectedFiles(file);
      validation.setFieldValue("image", file); // Update formik value for image

      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setPreviewImage(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setPreviewImage(null);
    setSelectedFiles(null);
    validation.setFieldValue("image", null); // Reset formik value
  };
  const handleAddRow = () => {
    validation.setFieldValue("details", [
      ...validation.values.details,
      { product_id: "", variant_id: "", validity: "", validity_type: "", actual_amount: "", offer_amount: "", gross_amount: "", slot_type: "", status: "" }
    ]);
  };

  const handleRemoveRow = (index: any) => {
    const updatedRows = [...validation.values.details];
    updatedRows.splice(index, 1);
    validation.setFieldValue("details", updatedRows);
  };


  const scrollToError = (errors: any) => {
    const errorFields = Object.keys(errors);
    if (errorFields.length > 0) {
      const errorElement = document.querySelector(`[name="${errorFields[0]}"]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const scrollToArrayError = (errors: any) => {
    const errorFields = Object.keys(errors);
    if (errorFields.length > 0) {
      const errorElement = document.querySelector(`[name="${errorFields[0]}"]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        for (const field of errorFields) {
          if (Array.isArray(errors[field])) {
            const nestedErrorElement = document.querySelector(`[name="${field}[0]"]`);
            if (nestedErrorElement) {
              nestedErrorElement.scrollIntoView({ behavior: "smooth", block: "center" });
              break;
            }
          }
        }
      }
    }
  };

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
      <Container fluid>
        <BreadCrumb title="Edit Subscription Products" pageTitle="Ecommerce" />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            if (Object.keys(validation.errors).length > 0) {
              scrollToError(validation.errors);
              scrollToArrayError(validation.errors);
            }
            return false;
          }}
        >
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Plan Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="product-title-input"
                      placeholder="Enter Plan Name"
                      name="name"
                      value={validation.values.name || ""}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.name && validation.touched.name
                          ? true
                          : false
                      }
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div>
                    <Label>Plan Description</Label>
                    <ReactQuill
                      theme="snow"
                      value={validation.values.description}
                      onChange={(value: any) => validation.setFieldValue("description", value)}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, false] }],
                          [{ font: [] }],
                          [{ size: ["small", false, "large", "huge"] }],
                          [{ color: [] }, { background: [] }],
                          ["bold", "italic", "underline"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ align: [] }],
                          ["link", "image"],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "color",
                        "background",
                        "bold",
                        "italic",
                        "underline",
                        "list",
                        "bullet",
                        "align",
                        "link",
                        "image",
                      ]}
                    />
                    {validation.errors.description && validation.touched.description ? (
                      <FormFeedback type="invalid">
                        {validation.errors.description}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <Card className="pb-4">
                <CardHeader>
                  <h5 className="card-title mb-0"> Image</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-4 text-center">
                    <div className="position-relative d-inline-block">
                      {/* Image Select Option */}
                      <div className="position-absolute top-100 start-100 translate-middle">
                        <Label
                          htmlFor="type-image-input"
                          className="mb-0 cursor-pointer"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          title="Select Image"
                        >
                          <div className="avatar-xs">
                            <div className="avatar-title bg-light border rounded-circle text-muted">
                              <i className="ri-image-fill"></i>
                            </div>
                          </div>
                        </Label>
                        <Input
                          name="formImage"
                          className="form-control d-none"
                          id="type-image-input"
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={handleImageChange}
                          invalid={
                            validation.touched.image && validation.errors.image
                              ? true
                              : false
                          }
                        />
                      </div>

                      {/* Image Preview with Remove Icon */}
                      <div className="avatar-lg position-relative">
                        <div className="avatar-title bg-light rounded">
                          {previewImage ? (
                            <>
                              {/* Close (Remove) Button */}
                              <button
                                type="button"
                                className="btn btn-sm btn-danger position-absolute top-0 end-0 rounded-circle"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "12px",
                                  padding: "0",
                                  border: "none",
                                }}
                                onClick={handleRemoveImage}
                              >
                                ✕
                              </button>

                              {/* Image Preview */}
                              <img
                                src={previewImage}
                                id="product-img"
                                alt="Selected"
                                className="avatar-md h-auto"
                              />
                            </>
                          ) : (
                            <span className="text-muted">No Image</span>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Validation Feedback */}
                    {validation.errors.image && validation.touched.image ? (
                      <FormFeedback type="invalid">
                        {validation.errors.image}
                      </FormFeedback>
                    ) : null}
                  </div>

                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Plan type</h5>
                </CardHeader>
                <CardBody>
                  <p className="text-muted mb-2">
                    {" "}
                    <Link
                      to="#"
                      className="float-end text-decoration-underline"
                      onClick={() => console.log("Open Modal")}
                    >
                      Add New
                    </Link>
                    Select type
                  </p>
                  <select
                    className={`form-control ${validation.errors.type && validation.touched.type ? "is-invalid" : ""}`}
                    id="price-select"
                    name="type"
                    aria-placeholder="Select offer type"
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    value={validation.values.type}
                  >
                    <option value="">Select Type</option>
                    <option value="1">Regular</option>
                    <option value="2">Combo</option> {/* Changed value from 1 to 2 */}
                    <option value="3">Trial (Free)</option>
                  </select>

                  {validation.touched.type && validation.errors.type ? (
                    <FormFeedback type="invalid">
                      {validation.errors.type}
                    </FormFeedback>
                  ) : null}
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Features</h5>
                </CardHeader>
                <CardBody>
                  <p className="text-muted mb-2">
                    {" "}
                    <Link
                      to="#"
                      className="float-end text-decoration-underline"
                      onClick={() => console.log("Open Modal")}
                    >
                      Add New
                    </Link>
                    Select features
                  </p>
                  <select
                    className={`form-control ${validation.errors.plan_feature_id && validation.touched.plan_feature_id
                      ? "is-invalid"
                      : ""
                      }`}
                    id="price-select"
                    name="plan_feature_id"
                    aria-placeholder="select offer type"
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    value={validation.values.plan_feature_id}
                  >
                    <option value="">Select features</option>
                    {availableFeature.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  {validation.touched.plan_feature_id && validation.errors.plan_feature_id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.plan_feature_id}
                    </FormFeedback>
                  ) : null}
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Publish</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label
                      htmlFor="choices-publish-status-input"
                      className="form-label"
                    >
                      Status
                    </Label>
                    <select
                      className={`form-control ${validation.errors.status && validation.touched.status
                        ? "is-invalid"
                        : ""
                        }`}
                      id="price-select"
                      name="status"
                      aria-placeholder="select offer type"
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      value={validation.values.status}
                    >
                      <option value="">Select Publish status</option>
                      <option value={1}>Draft</option>
                      <option value={2}>Published</option>
                      <option value={3}>Scheduled</option>
                    </select>
                    {validation.touched.status && validation.errors.status ? (
                      <FormFeedback type="invalid">
                        {validation.errors.status}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        Variants
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="1">
                      <Row>
                        <div className="table-responsive mt-3">
                          <Table bordered className="table">
                            <thead>
                              <tr>
                                <th className="text-center w-10">S.No</th>
                                <th className="text-center w-200">
                                  Product ID
                                  <p className="col-lg-6">
                                    <Link to="#" className="float-end text-decoration-underline" onClick={() => console.log("Open Modal")}>
                                      Add New
                                    </Link>
                                  </p>
                                </th>
                                <th className="text-center w-150">Variant ID</th>
                                <th className="text-center w-150">Validity</th>
                                <th className="text-center w-150">Validity Type</th>
                                <th className="text-center w-150">Actual Amount</th>
                                <th className="text-center w-150">Offer Amount</th>
                                <th className="text-center w-150">Gross Amount</th>
                                <th className="text-center w-150">Slot Type</th>
                                <th className="text-center w-150">Status</th>
                                <th className="text-center w-150">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray
                                name="details"
                                validateOnChange={false} // Add this line to prevent the error
                                render={(arrayHelpers) => (
                                  <>
                                    {validation.values.details.map((item: any, index: any) => (
                                      <tr key={index}>
                                        <td className="text-center">{index + 1}</td>
                                        <td>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name={`details[${index}].product_id`}
                                            value={validation.values.details?.[index]?.product_id || ""}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.details?.[index]?.product_id && validation.touched.details?.[index]?.product_id)}
                                          />
                                          {validation.errors.details?.[index]?.product_id && validation.touched.details?.[index]?.product_id ? (
                                            <FormFeedback>{validation.errors.details?.[index]?.product_id}</FormFeedback>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name={`details[${index}].variant_id`}
                                            value={validation.values.details?.[index]?.variant_id || ""}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.details?.[index]?.variant_id && validation.touched.details?.[index]?.variant_id)}
                                          />
                                          {validation.errors.details?.[index]?.variant_id && validation.touched.details?.[index]?.variant_id ? (
                                            <FormFeedback>{validation.errors.details?.[index]?.variant_id}</FormFeedback>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name={`details[${index}].validity`}
                                            value={validation.values.details?.[index]?.validity || ""}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.details?.[index]?.validity && validation.touched.details?.[index]?.validity)}
                                          />
                                          {validation.errors.details?.[index]?.validity && validation.touched.details?.[index]?.validity ? (
                                            <FormFeedback>{validation.errors.details?.[index]?.validity}</FormFeedback>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name={`details[${index}].validity_type`}
                                            value={validation.values.details?.[index]?.validity_type || ""}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.details?.[index]?.validity_type && validation.touched.details?.[index]?.validity_type)}
                                          />
                                          {validation.errors.details?.[index]?.validity_type && validation.touched.details?.[index]?.validity_type ? (
                                            <FormFeedback>{validation.errors.details?.[index]?.validity_type}</FormFeedback>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name={`details[${index}].actual_amount`}
                                            value={validation.values.details?.[index]?.actual_amount || ""}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.details?.[index]?.actual_amount && validation.touched.details?.[index]?.actual_amount)}
                                          />
                                          {validation.errors.details?.[index]?.actual_amount && validation.touched.details?.[index]?.actual_amount ? (
                                            <FormFeedback>{validation.errors.details?.[index]?.actual_amount}</FormFeedback>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name={`details[${index}].offer_amount`}
                                            value={validation.values.details?.[index]?.offer_amount || ""}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.details?.[index]?.offer_amount && validation.touched.details?.[index]?.offer_amount)}
                                          />
                                          {validation.errors.details?.[index]?.offer_amount && validation.touched.details?.[index]?.offer_amount ? (
                                            <FormFeedback>{validation.errors.details?.[index]?.offer_amount}</FormFeedback>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name={`details[${index}].gross_amount`}
                                            value={validation.values.details?.[index]?.gross_amount || ""}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.details?.[index]?.gross_amount && validation.touched.details?.[index]?.gross_amount)}
                                          />
                                          {validation.errors.details?.[index]?.gross_amount && validation.touched.details?.[index]?.gross_amount ? (
                                            <FormFeedback>{validation.errors.details?.[index]?.gross_amount}</FormFeedback>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name={`details[${index}].slot_type`}
                                            value={validation.values.details?.[index]?.slot_type || ""}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.details?.[index]?.slot_type && validation.touched.details?.[index]?.slot_type)}
                                          />
                                          {validation.errors.details?.[index]?.slot_type && validation.touched.details?.[index]?.slot_type ? (
                                            <FormFeedback>{validation.errors.details?.[index]?.slot_type}</FormFeedback>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name={`details[${index}].status`}
                                            value={validation.values.details?.[index]?.status || ""}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.details?.[index]?.status && validation.touched.details?.[index]?.status)}
                                          />
                                          {validation.errors.details?.[index]?.status && validation.touched.details?.[index]?.status ? (
                                            <FormFeedback>{validation.errors.details?.[index]?.status}</FormFeedback>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Button color="primary" className="me-2" onClick={handleAddRow} disabled={index !== validation.values.details.length - 1}>
                                            +
                                          </Button>
                                          {validation.values.details.length > 1 && (
                                            <Button color="danger" onClick={() => handleRemoveRow(index)}>
                                              Remove
                                            </Button>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              />
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="text-end mb-3">
            <button type="submit" className="btn btn-success w-sm">
              Submit
            </button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default EditPlanSubscription;
