import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Custom_URL = `${API_URL}/custom`;
const Product_URL = `${API_URL}/subscription`;

const listBrand = () => {
  return axios.get(`${Custom_URL}/list-brand`).then((response) => {
    return response;
  });
};

const listFeatuer = () => {
  return axios.get(`${Custom_URL}/list-featuer`).then((response) => {
    return response;
  });
};
const listSpeacification = () => {
  return axios.get(`${Custom_URL}/list-speacification`).then((response) => {
    return response;
  });
};
const listVariant = () => {
  return axios.get(`${Custom_URL}/list-variant`).then((response) => {
    return response;
  });
};
const listCategory = () => {
  return axios.get(`${Custom_URL}/list-category`).then((response) => {
    return response;
  });
};

const listProduct = () => {
  return axios.get(`${Product_URL}/list-subscription-products`).then((response) => {
    return response;
  });
};

const createProduct = (formData: FormData) => {
  return axios.post(`${Product_URL}/add`,  formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
    }}).then((response) => {
    return response;
  });
};

const updateProduct = (formData: FormData, id: any) => {
  return axios.put(`${Product_URL}/edit/${id}`,  formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
    }}).then((response) => {
    return response;
  });
};

const viewProduct = (id: any) => {
  return axios.get(`${Product_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deleteProduct = (id: any) => {
  return axios.put(`${Product_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const createPlanSubscription = async (data: FormData) => {
  try {
    const response = await axios.post(`${Product_URL}/add-subscription-products`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error("Error creating plan subscription:", error);
    return { status: false, message: "Failed to create plan subscription" };
  }
};
const editPlanSubscription = async (id:any,data: FormData) => {
  try {
    const response = await axios.put(`${Product_URL}/edit-subscription-products/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error("Error creating plan subscription:", error);
    return { status: false, message: "Failed to create plan subscription" };
  }
};
const viewPlanSubscription = (id: any) => {
  return axios.get(`${Product_URL}/view-subscription-products/${id}`).then((response) => {
    return response;
  });
};
export {
  createPlanSubscription,
  editPlanSubscription,
  viewPlanSubscription,
  listBrand,
  listFeatuer,
  listSpeacification,
  listVariant,
  listCategory,
  listProduct,
  createProduct,
  updateProduct,
  viewProduct,
  deleteProduct
};
