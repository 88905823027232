/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPlanFeatures, deletePlanFeatures, listPlanFeatures, updatePlanFeatures } from "./_requests";
import { useProfile } from "../../Components/Hooks/UserHooks";

const PlanFeatures = () => {
  const { userProfile } = useProfile();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [PlanFeatures, setPlanFeatures] = useState<any[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [departmentToDeleteId, setDepartmentToDeleteId] = useState<string>("");
  const [departmentToDeleteName, setDepartmentToDeleteName] = useState<string>(
    ""
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [PlanFeaturesAccess, setPlanFeaturesAccess] = useState<any>([]);

  useEffect(() => {
    // getCRUD(userProfile?.role, Data).then((res: any) => {
    //   setPlanFeaturesAccess(res.Data[0]);
    // });
  }, []);

  const addPlanFeatures = () => {
    // if (PlanFeaturesAccess?.add === 1) {
      toggle();
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  const editPlanFeatures = () => {
    // if (PlanFeaturesAccess?.edit === 1) {
      toggle();
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const prepareDelete = (departmentId: string, departmentName: string) => {
    setDepartmentToDeleteId(departmentId);
    setDepartmentToDeleteName(departmentName);
    setPlanFeaturesAccess((prevAgenAccess: any) => {
      // if (prevAgenAccess?.remove === 1) {
        toggleDeleteModal();
      // } else {
      //   toast.error("You don't have permission to access this page", {
      //     position: "top-right",
      //     style: {
      //       backgroundColor: "#9b2313",
      //       color: "#c7c7d7",
      //       fontSize: "14px",
      //     },
      //     closeButton: false,
      //   });
      // }
      return prevAgenAccess;
    });
    //
  };

  const confirmDelete = (departmentId: string) => {
    deletePlanFeatures(departmentId)
      .then((res:any) => {
        FetchData();
        toggleDeleteModal();
        toast.success(res.message, { autoClose: 3000 });
      })
      .catch((error) => {
        console.error("Error deleting role:", error);
      });
  };

  useEffect(() => {
    FetchData();
  }, []);
  const FetchData = () => {
    listPlanFeatures().then((res: any) => {
      setPlanFeatures(res.data);
    });
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Plan Features name is required"),
    }),
    onSubmit: async (values: any) => {
      const userId = userProfile?.userId; // Replace this with dynamic user ID if available (e.g., from auth context)

      const planData = {
        name: values.name,
        ...(isEdit ? { updated_by: userId } : { created_by: userId }), // Dynamically add created_by or updated_by
      };

      try {
        if (isEdit) {
          await updatePlanFeatures(planData, values.id).then((res:any)=>{
            toast.success(res.message, { autoClose: 3000 });
          })
        } else {
          await createPlanFeatures(planData).then((res:any)=>{
            toast.success(res.message, { autoClose: 3000 });
          })
        }
        FetchData();
        validation.resetForm(); // Reset form after success
      } catch (error) {
        toast.error("An error occurred, please try again.", {
          autoClose: 3000,
        });
      }

      toggle(); // Close modal
    },
  });

  document.title = " Paal Dappa | Admin panel";

  // Filter PlanFeatures based on search query
  const filteredPlanFeatures = PlanFeatures.filter((bill) =>
    bill.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="g-3">
                <div className="col-lg-3 col-auto">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control search"
                      id="search-task-options"
                      placeholder="Search Plan Features..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex hastck gap-2 flex-wrap">
                    <button
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                      onClick={() => {
                        setIsEdit(false);
                        addPlanFeatures();
                        validation.setValues({ name: "" });
                      }}
                    >
                      <i className="ri-add-fill align-bottom me-1"></i>
                      Add Plan Features
                    </button>
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>

          <Row className="mt-4">
            {filteredPlanFeatures.length === 0 ? (
              <div className="text-center">No search data found</div>
            ) : (
              filteredPlanFeatures.map((PlanFeatures: any, key: number) => (
                <React.Fragment key={key}>
                  <Col xl={3} lg={6}>
                    <Card className="card card-height-100 shadow-none bg-opacity-10">
                      <CardBody className="p-4">
                        <UncontrolledDropdown className="float-end">
                          <DropdownToggle
                            tag="a"
                            className="text-reset dropdown-btn"
                            href="#"
                          >
                            <span className="text-muted fs-18">
                              <i className="mdi mdi-dots-vertical"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end" end>
                            <DropdownItem
                              onClick={() => {
                                setIsEdit(true);
                                editPlanFeatures();
                                validation.setValues({
                                  name: PlanFeatures.name,
                                  id: PlanFeatures.id,
                                }); // Set initial values for form fields
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                prepareDelete(PlanFeatures.id, PlanFeatures.name)
                              }
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <h5 className="m-2 text start">
                          <i className="las la-university me-1"></i>{" "}
                          {PlanFeatures.name}
                        </h5>
                        <Row className="mt-2">
                          {/* <p className="text-muted m-2 ms-4 text start">Total {1} agencys</p> */}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              ))
            )}
          </Row>
          <ToastContainer closeButton={false} limit={1} />
        </Container>
      </div>

      <Modal id="adddeals" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {!!isEdit ? "Edit Plan Features" : "Create Plan Features"}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="contactDescription" className="form-label">
               Name
              </label>
              <Input
                type="text"
                className="form-control"
                id="contactDescription"
                rows={3}
                placeholder="Enter  name"
                name="name"
                value={validation.values.name || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="light"
              id="close-modal"
              onClick={() => {
                setIsEdit(false);
                setModal(false);
                validation.resetForm();
              }}
            >
              Close
            </Button>
            <Button type="submit"   className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }}>
              <i className="ri-save-line align-bottom me-1"></i>{" "}
              {!!isEdit ? "Update" : "Save"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the Plan Features{" "}
          <strong>{departmentToDeleteName}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button  className="btn text-white add-btn me-1"
                              style={{
                                backgroundColor: "#28a8df",
                                borderColor: "#28a8df",
                              }} onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button
              className="btn text-white add-btn me-1"
              style={{
                backgroundColor: "#28a8df",
                borderColor: "#28a8df",
              }}
            onClick={() => {
              confirmDelete(departmentToDeleteId);
              setModal(false);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default PlanFeatures;
