import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Delivery_URL = `${API_URL}/delivery`;

const listDelivery = () => {
  return axios.get(`${Delivery_URL}/list`).then((response) => {
    return response;
  });
};

const createDelivery = (data: any) => {
  return axios.post(`${Delivery_URL}/add`, data).then((response) => {
    return response;
  });
};

const updateDelivery = (data: any, id: any) => {
  return axios.put(`${Delivery_URL}/edit/${id}`, data).then((response) => {
    return response;
  });
};

const getDelivery = (id: any) => {
  return axios.get(`${Delivery_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deleteDelivery = (id: any) => {
  return axios.put(`${Delivery_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${Delivery_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listDelivery,
  createDelivery,
  updateDelivery,
  getDelivery,
  deleteDelivery,
  checkDuplicate,
};
