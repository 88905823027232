import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavLink,
  NavItem,
  Row,
} from "reactstrap";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import moment from "moment";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { viewSettings } from "./_requests";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DOMPurify from "dompurify";

export function ViewSettings() {
  const { id } = useParams();
  const { userProfile } = useProfile();
  const history = useNavigate();
  const [Settings, setSettings] = useState<any>([]);

  useEffect(() => {
    viewSettings(id).then((res: any) => {
      setSettings(res);
    });
  }, []);

  const [PlanAccess, setPlanAccess] = useState<any>([]);

  useEffect(() => {
    // getCRUD(SettingsProfile?.role, 17).then((res: any) => {
    //   setPlanAccess(res.Data[0]);
    // });
  }, []);

  //
  document.title = " Paal Dappa | Admin panel";
  function goBack() {
    history(-1);
  }

  const type: Record<number, string> = {
    1: "User App",
    2: "Driver App",
  };
  const settingsFields = [
    { label: "About", value: Settings?.about },
    { label: "Terms And Condition", value: Settings?.terms_and_condition },
    { label: "Privacy Policy", value: Settings?.privacy_policy },
  ];

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <ToastContainer closeButton={false} limit={1} />
            <BreadCrumb title=" View Settings " pageTitle="Settings" />
            <Row>
              <Col>
                {" "}
                <button
                  onClick={goBack}
                  className="btn btn-primary px-2 text-center mb-3"
                >
                  <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
                </button>{" "}
              </Col>
            </Row>
            <Card>
              <CardHeader>
                <Row className="g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">Settings Details</h5>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <Link to={`/settings/edit/${id}`}>
                      {" "}
                      <i className="ri-pencil-fill align-bottom me-1"></i> Edit
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-4">Setting Type</Col>
                  <Col className="col-3">
                    {type[Settings?.settings_type] || "Unknown"}{" "}
                  </Col>
                </Row>
                {settingsFields.map((field, index) => (
                  <Row key={index} className="mb-3">
                    <Col className="col-4">{field.label}</Col>
                    <Col className="col-3">
                      {/* Render dynamic content */}
                      {typeof field.value === "string" ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(field.value || ""),
                          }}
                        />
                      ) : (
                        field.value // If it's not a string (e.g., a number or other content), render directly
                      )}
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
