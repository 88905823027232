import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

interface OrderStatusModalProps {
  isOpen: boolean;
  toggle: () => void;
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
  orderStatusLists: { id: string; name: string }[];
  handleStatus: () => void;
}

const OrderStatusModal: React.FC<OrderStatusModalProps> = ({
  isOpen,
  toggle,
  selectedStatus,
  setSelectedStatus,
  orderStatusLists,
  handleStatus,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Update Order Status</ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <label className="form-label" htmlFor="status-select">
            Order Status
          </label>
          <select
            className="form-control"
            id="status-select"
            name="orderStatus"
            onChange={(e) => setSelectedStatus(e.target.value)}
            value={selectedStatus}
          >
            <option value="">Select order status</option>
            {orderStatusLists.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Discard
        </Button>
        <Button color="primary" onClick={handleStatus}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OrderStatusModal;
