import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Route_URL = `${API_URL}/route`;

const listStore = () => {
  return axios.get(`${API_URL}/custom/list-store`).then((response) => {
    return response;
  });
};
const listRoute = () => {
  return axios.get(`${Route_URL}/list`).then((response) => {
    return response;
  });
};

const createRoute = (data: any) => {
  return axios.post(`${Route_URL}/add`, data ).then((response) => {
    return response;
  });
};


const updateRoute = (data: any, id: any) => {
  return axios.put(`${Route_URL}/edit/${id}`, data ).then((response) => {
    return response;
  });
};

const viewRoute = (id: any) => {
  return axios.get(`${Route_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};


const deleteRoute = (id: any, userId: number) => {
  return axios.put(`${Route_URL}/delete/${id}`, { userId }).then((response) => {
    return response.data;
  });
};


export { listStore , listRoute, deleteRoute, viewRoute, createRoute, updateRoute };
