/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,

  Button,
} from "reactstrap";
import {  Link, useNavigate } from "react-router-dom";
// Formik
import DOMPurify from 'dompurify';

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import { deleteSettings, listSettings } from "./_requests";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";

const Settings = (data: any) => {
  const navigate=useNavigate();
  const [Settings, setSettings] = useState<any>([]);
  const [Settingsaccess, setrolecaccess] = useState<any>([]);
  const { userProfile } = useProfile();
  const [modal, setModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [SettingsToDeleteId, setSettingsToDeleteId] = useState<string>("");
  const [SettingsToDeleteName, setSettingsToDeleteName] = useState<string>("");
  const [Duplicate, setDuplicate] = useState<any>("");
  const allowedTags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const toggle = useCallback(() => {
    setModal(!modal);
    setDuplicate("");
  }, [modal]);

  useEffect(() => {
    // getCRUD(userProfile?.role).then((res: any) => {
    //   setrolecaccess(res.Data[0]);
    // });
  }, []);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = () => {
    listSettings().then((res: any) => {
      setSettings(res);
    });
  };

  const addSettings =()=>{
    navigate("/settings/add")
  }

  // Delete Data
  const onClickDelete = useCallback(
    (Settings: any) => {
      setSettingsToDeleteName(Settings.name);
      setSettingsToDeleteId(Settings.id);

      setrolecaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.remove === 1) {
        toggleDeleteModal();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [setSettingsToDeleteName, setSettingsToDeleteId, toggleDeleteModal]
  );

  const confirmDelete = async (SettingsId: any) => {
    await deleteSettings(SettingsId);
    toggleDeleteModal();
    toast.success("Settings deleted Successfully", { autoClose: 3000 });
    fetchSettings();
  };
  

  // Settingss Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Type",
        accessorKey: "settings_type",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const data = cell.getValue();
          const id = cell.row.original.id;
        
          // Type mapping: number to string
          const type: Record<number, string> = {
            1: "User App",
            2: "Driver App",
          };
        
          // Use type[data] safely, with a fallback value in case data is not found
          const label = type[data] || "Unknown"; // Default to "Unknown" if no match
        
          return <Link to={`/settings/view/${id}`}>{label}</Link>;
        }
        

      },
      {
        header: "About",
        accessorKey: "about",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div
              className="d-flex align-items-center"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(cell.getValue(), { ALLOWED_TAGS: allowedTags }) || '-',
              }}
            />
          </>
        ),
      },
      {
        header: "Terms And Condition",
        accessorKey: "terms_and_condition",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div
              className="d-flex align-items-center"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(cell.getValue(), { ALLOWED_TAGS: allowedTags }) || '-',
              }}
            />
          </>
        ),
      },
      {
        header: "Privacy Policy",
        accessorKey: "privacy_policy",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div
              className="d-flex align-items-center"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(cell.getValue(), { ALLOWED_TAGS: allowedTags }) || '-',
              }}
            />
          </>
        ),
      }
      // {
      //   header: "Cancellation Policy",
      //   accessorKey: "cancellation_policy",
      //   enableColumnFilter: false,
      //   cell: (cell: any) => (
      //     <>
      //       <div className="d-flex align-items-center">
      //         {cell.getValue() || "-"}
      //       </div>
      //     </>
      //   ),
      // }
    ],
    [onClickDelete]
  );

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Settings" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="SettingsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Settings List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          id="create-btn"
                          onClick={addSettings}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Settings
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Settings || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Settings..."
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the Settings{" "}
              <strong>{SettingsToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={toggleDeleteModal}
              >
                Cancel
              </Button>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={() => {
                  confirmDelete(SettingsToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
