/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { createVariant, deleteVariant, listVariant, updateVariant } from "./_requests";


const Variant = () => {
  const { userProfile } = useProfile();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [Variant, setVariant] = useState<any[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [varientToDeleteId, setvarientToDeleteId] = useState<string>("");
  const [varientToDeleteName, setvarientToDeleteName] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [VariantAccess, setVariantAccess] = useState<any>([]);
  const [selected, setSelected] = useState("");
  const handleChange = (e:any) => {
    const newValue = e.target.value;
    setSelected(newValue);
    validation.handleChange(e);
  };
  useEffect(() => {
    // getCRUD(userProfile?.role, Data).then((res: any) => {
    //   setVariantAccess(res.Data[0]);
    // });
  }, []);

  const addVariant = () => {
    // if (VariantAccess?.add === 1) {
    toggle();
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  const editVariant = () => {
    // if (VariantAccess?.edit === 1) {
    toggle();
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const prepareDelete = (varientId: string, varientName: string) => {
    setvarientToDeleteId(varientId);
    setvarientToDeleteName(varientName);
    setVariantAccess((prevAgenAccess: any) => {
      // if (prevAgenAccess?.remove === 1) {
      toggleDeleteModal();
      // } else {
      //   toast.error("You don't have permission to access this page", {
      //     position: "top-right",
      //     style: {
      //       backgroundColor: "#9b2313",
      //       color: "#c7c7d7",
      //       fontSize: "14px",
      //     },
      //     closeButton: false,
      //   });
      // }
      return prevAgenAccess;
    });
    //
  };

  const confirmDelete = (varientId: string) => {
    deleteVariant(varientId)
      .then(() => {
        FetchData();
        toggleDeleteModal();
        toast.success("Variant deleted Successfully", { autoClose: 3000 });
      })
      .catch((error) => {
        console.error("Error deleting role:", error);
      });
  };

  useEffect(() => {
    FetchData();
  }, []);
  const FetchData = () => {
    listVariant().then((res: any) => {
      setVariant(res.data);
    });
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      category: "",
      name: "",
      value: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Variant name is required"),
      category: Yup.string().required("Category is required (Ex: Size,Volume, Color"),
      // value: Yup.string().required("Name Expension is required.(L- Large, , S- Small) "),
    }),
    onSubmit: (values: any) => {
      try {
        const userId = userProfile.userId
        const data = {
          name: values.name,
          category: values.category,
          value: values.value || "",
          ...(isEdit ? { updated_by: userId } : { created_by: userId })
        };

        const actionPromise = isEdit ? updateVariant(data, values.id) : createVariant(data);

        actionPromise.then((res: any) => {
          toast.success(res.message, { autoClose: 3000 });
          FetchData(); // Fetch latest data after successful update/create
          validation.resetForm();
          toggle();
        }).catch((error: any) => {
          console.error("Error:", error);
          toast.error("Something went wrong", { autoClose: 3000 });
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong", { autoClose: 3000 });
      }
    }
  });

  document.title = " Paal Dappa | Admin panel";

  // Filter Variant based on search query
  const filteredVariant = Variant?.filter((bill) =>
    bill.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="g-3">
                <div className="col-lg-3 col-auto">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control search"
                      id="search-task-options"
                      placeholder="Search Variant..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex hastck gap-2 flex-wrap">
                    <button
                      className="btn text-white add-btn me-1"
                      style={{
                        backgroundColor: "#28a8df",
                        borderColor: "#28a8df",
                      }}
                      onClick={() => {
                        setIsEdit(false);
                        addVariant();
                        validation.setValues({ name: "" });
                      }}
                    >
                      <i className="ri-add-fill align-bottom me-1"></i>
                      Add Variant
                    </button>
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>

          <Row className="mt-4">
            {filteredVariant.length === 0 ? (
              <div className="text-center">No search data found</div>
            ) : (
              filteredVariant.map((Variant: any, key: number) => (
                <React.Fragment key={key}>
                  <Col xs={12} sm={6} md={4} lg={3} className="mb-3">
                    <Card className="shadow-sm bg-opacity-10 border-0">
                      <CardBody className="p-4">
                        <UncontrolledDropdown className="float-end">
                          <DropdownToggle tag="a" className="text-reset dropdown-btn" href="#">
                            <span className="text-muted fs-18">
                              <i className="mdi mdi-dots-vertical"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end" end>
                            <DropdownItem
                              onClick={() => {
                                setIsEdit(true);
                                editVariant();
                                validation.setValues({
                                  id: Variant.id,
                                  name: Variant.name,
                                  category: Variant.category,
                                  value: Variant.value,
                                });
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem onClick={() => prepareDelete(Variant.id, Variant.name)}>
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <h5 className="m-2 text-start">
                          <i className="las la-university me-1"></i> {Variant.name} {Variant.value && `(${Variant.value})`}
                        </h5>

                        <h6 className="m-2 text-start text-muted">
                          <i className="las la-list me-1"></i> {Variant.category}
                        </h6>
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>

              ))
            )}
          </Row>
          <ToastContainer closeButton={false} limit={1} />
        </Container>
      </div>

      <Modal id="adddeals" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {!!isEdit ? "Edit Variant" : "Create Variant"}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="variantCategory" className="form-label">
                Variant Category
              </label>
              <Input
                type="select"
                className="form-control"
                id="variantCategory"
                name="category"
                value={validation.values.category || ""}
                // onChange={validation.handleChange}
                onChange={handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.category && validation.errors.category
                    ? true
                    : false
                }
              >
                 <option value="">Select Variant Category</option>
        <option value="Size">
          Size {selected !== "Size" && "(e.g., Small, Medium, Large)"}
        </option>
        <option value="Color">
          Color {selected !== "Color" && "(e.g., Red, Blue, Green)"}
        </option>
        <option value="Weight">
          Weight {selected !== "Weight" && "(e.g., 500g, 1kg, 2kg)"}
        </option>
        <option value="Volume">
          Volume {selected !== "Volume" && "(e.g., 250ml, 500ml, 1L)"}
        </option>
        <option value="Material">
          Material {selected !== "Material" && "(e.g., Cotton, Leather, Plastic)"}
        </option>
        <option value="Style">
          Style {selected !== "Style" && "(e.g., Modern, Classic, Vintage)"}
        </option>
              </Input>
              {validation.touched.category && validation.errors.category ? (
                <FormFeedback type="invalid">
                  {validation.errors.category}
                </FormFeedback>
              ) : null}
            </div>


            <div className="mb-3">
              <label htmlFor="contactDescription" className="form-label">
                Variant Name
              </label>
              <Input
                type="text"
                className="form-control"
                id="contactDescription"
                rows={3}
                placeholder="Enter Variant name (L, M, Red, Kg, etc..)"
                name="name"
                value={validation.values.name || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="contactDescription" className="form-label">
                Variant Name Expension
              </label>
              <Input
                type="text"
                className="form-control"
                id="contactDescription"
                rows={3}
                placeholder="Enter Variant name (L- Large, S- Small , M- Medium"
                name="value"
                value={validation.values.value || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.value && validation.errors.value
                    ? true
                    : false
                }
              />
              {validation.touched.value && validation.errors.value ? (
                <FormFeedback type="invalid">
                  {validation.errors.value}
                </FormFeedback>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="light"
              id="close-modal"
              onClick={() => {
                setIsEdit(false);
                setModal(false);
                validation.resetForm();
              }}
            >
              Close
            </Button>
            <Button type="submit" className="btn text-white add-btn me-1"
              style={{
                backgroundColor: "#28a8df",
                borderColor: "#28a8df",
              }}>
              <i className="ri-save-line align-bottom me-1"></i>{" "}
              {!!isEdit ? "Update" : "Save"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the Variant{" "}
          <strong>{varientToDeleteName}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button className="btn text-white add-btn me-1"
            style={{
              backgroundColor: "#28a8df",
              borderColor: "#28a8df",
            }} onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button
            className="btn text-white add-btn me-1"
            style={{
              backgroundColor: "#28a8df",
              borderColor: "#28a8df",
            }}
            onClick={() => {
              confirmDelete(varientToDeleteId);
              setModal(false);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Variant;
