/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";
import {
  createRoute,
  deleteRoute,
  listRoute,
  listStore,
  updateRoute,
} from "./_requests";

const Route = (data: any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [Route, setRoute] = useState<any>([]);
  const [Store, setStore] = useState<any>([]);
  const [Routeaccess, setRouteaccess] = useState<any>([]);
  const { userProfile } = useProfile();
  const [modal, setModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [RouteToDeleteId, setRouteToDeleteId] = useState<string>("");
  const [RouteToDeleteName, setRouteToDeleteName] = useState<string>("");
  const [Duplicate, setDuplicate] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<any>();
  const [Email, setEmail] = useState("");
  const toggle = useCallback(() => {
    setModal(!modal);
    setDuplicate("");
  }, [modal]);

  useEffect(() => {
    // getCRUD(userProfile?.role, ).then((res: any) => {
    //   setRouteaccess(res.Data[0]);
    // });

    listStore().then((res: any) => {
      setStore(res.data);
    });
  }, []);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    fetchRoute();
  }, []);

  const fetchRoute = () => {
    listRoute().then((res: any) => {
      setRoute(res.data);
    });
  };

  // Delete Data
  const onClickDelete = useCallback(
    (Route: any) => {
      setRouteToDeleteName(Route.name);
      setRouteToDeleteId(Route.id);

      setRouteaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.remove === 1) {
        toggleDeleteModal();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [setRouteToDeleteName, setRouteToDeleteId, toggleDeleteModal]
  );

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      store_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      store_id: Yup.string().required("Store is required"),
    }),
    onSubmit: async (values: any) => {
      try {
        const userId = userProfile.userId;
        const data = {
          name: values.name,
          store_id: values.store_id,
          ...(isEdit ? { updated_by: userId } : { created_by: userId }),
        };

        const actionPromise = isEdit
          ? updateRoute(data, values.id)
          : createRoute(data);
        actionPromise
          .then((res: any) => {
            toast.success(res.message, { autoClose: 3000 });
            fetchRoute(); // Fetch latest data after successful update/create
            validation.resetForm();
            toggle();
          })
          .catch((error: any) => {
            console.error("Error:", error);
            toast.error("Something went wrong", { autoClose: 3000 });
          });
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong", { autoClose: 3000 });
      }
    },
  });

  const confirmDelete = async (RouteId: any) => {
    await deleteRoute(RouteId, userProfile?.userId);
    toggleDeleteModal();
    toast.success("Route deleted Successfully", { autoClose: 3000 });
    fetchRoute();
  };

  // Update Data
  const handleRouteClick = useCallback(
    (Route: any) => {
      validation.setValues({
        id: Route.id,
        name: Route.name,
        store_id: Route.store.id,
      });
      setIsEdit(true);

      setRouteaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.edit === 1) {
        toggle();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [toggle, validation]
  );

  // const DuplicateCheck = (val: any) => {
  //   try {
  //     checkDuplicateEmail(val).then((response: any) => {
  //       if (isEdit) {
  //         if (response?.isDuplicate === true && Email !== val) {
  //           setDuplicate("Email already exists");
  //         } else {
  //           setDuplicate("");
  //         }
  //       } else {
  //         if (response?.isDuplicate === true) {
  //           setDuplicate("Email already exist");
  //         } else {
  //           setDuplicate("");
  //         }
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error checking for duplicate role:", error);
  //   }
  // };

  // Routes Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Store",
        accessorKey: "store.name",
        enableColumnFilter: false,
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const RouteData = cellProps.row.original;
                    handleRouteClick(RouteData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const RouteData = cellProps.row.original;
                    onClickDelete(RouteData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleRouteClick, onClickDelete]
  );

  const addRoute = () => {
    // if (Routeaccess?.add === 1) {
    setIsEdit(false);
    setSelectedImage("");
    toggle();
    validation.setValues({ name: "", email: "", roleId: "", password: "" });
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Route" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="RouteList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Route List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          id="create-btn"
                          onClick={addRoute}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Route
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Route || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Route..."
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Route" : "Add Route"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Routename-field"
                            className="form-label"
                          >
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            id="Routename-field"
                            className="form-control"
                            placeholder="Enter  Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="status-field"
                            className="form-label"
                          >
                            Store<span className="text-danger">*</span>
                          </Label>

                          <Input
                            name="store_id"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.store_id || ""}
                            invalid={
                              validation.touched.store_id &&
                              validation.errors.store_id
                                ? true
                                : false
                            }
                          >
                            <option>Select a store...</option>
                            {Store?.map((item: any, index: any) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.store_id &&
                          validation.errors.store_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.store_id}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>

                          <button
                            type="submit"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            disabled={Duplicate}
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add Route"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the Route{" "}
              <strong>{RouteToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={toggleDeleteModal}
              >
                Cancel
              </Button>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={() => {
                  confirmDelete(RouteToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Route;
