import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//formik
import { useFormik } from "formik";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { useToast } from "../../Components/Common/ToastContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createSettings } from "./_requests";

const AddSettings = (props: any) => {
  document.title = " Paal Dappa | Admin panel";
  const { userProfile } = useProfile();
  const { showToast } = useToast();
  const history = useNavigate();

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      settings_type: "",
      about: "",
      terms_and_condition: "",
      privacy_policy: "",
    },

    onSubmit: async (values) => {
      const data = {
        settings_type: values.settings_type,
        about: values.about,
        terms_and_condition: values.terms_and_condition,
        privacy_policy: values.privacy_policy,
      };

      // Save new product
      const res: any = await createSettings(data);
      if (res.status === true) {
        showToast(res.message, res.status);
        validation.resetForm();
        history("/settings");
      } else {
        showToast(res.message, res.status);
      }
    },
  });

  // Example price options
  const settings_types = [
    { value: "", label: "Select Setting Type" },
    { value: 1, label: "User App" },
    { value: 2, label: "Driver App" },
  ];

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: "38px", // Customize the height to match Bootstrap's Input height
    }),
  };
  console.log("validation.errors", validation.errors);

  // Custom Quill modules for toolbar
  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Header
      [{ font: [] }], // Font family
      [{ size: ["small", false, "large", "huge"] }], // Font size
      [{ color: [] }, { background: [] }], // Text color and background
      ["bold", "italic", "underline"], // Text style
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      [{ align: [] }], // Alignment
      ["link", "image"], // Insert links or images
      ["clean"], // Clear formatting
    ],
  };

  // Formats allowed by ReactQuill
  const quillFormats = [
    "header",
    "font",
    "size",
    "color",
    "background",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "link",
    "image",
  ];

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
      <Container fluid>
        <BreadCrumb title="Create Product" pageTitle="Ecommerce" />

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label
                      htmlFor="choices-publish-visibility-input"
                      className="form-label"
                    >
                      Setting Type
                    </Label>
                    <Input
                      name="settings_type"
                      type="select"
                      className="form-select"
                      id="choices-publish-status-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.settings_type || ""}
                    >
                      {settings_types.map((item: any, key: any) => (
                        <option value={item.value} key={key}>
                          {item.label}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div className="mb-3">
                    <Label>About</Label>

                    <ReactQuill
                      theme="snow"
                      value={validation.values.about}
                      onChange={(value: any) =>
                        validation.setFieldValue("about", value)
                      }
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Terms And Condition</Label>

                    <ReactQuill
                      theme="snow"
                      value={validation.values.terms_and_condition}
                      onChange={(value: any) =>
                        validation.setFieldValue("terms_and_condition", value)
                      }
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Privacy Policy</Label>

                    <ReactQuill
                      theme="snow"
                      value={validation.values.privacy_policy}
                      onChange={(value: any) =>
                        validation.setFieldValue("privacy_policy", value)
                      }
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="text-end mb-3">
            <button type="submit" className="btn btn-success w-sm">
              Submit
            </button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default AddSettings;
