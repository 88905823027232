import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { listOrder, OrderStatus, UpdateOrderStatus } from "./_requests";
import { Pencil } from "react-bootstrap-icons";
import OrderStatusModal from "./OrderStatusModal";
import { useToast } from "../../Components/Common/ToastContext";
import { listStore } from "../../pages/Route/_requests";
import { listRoute } from "../../pages/Route/_requests";
import OrderRouteUpdate from "./OrderRouteUpdate";

const Orders = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("1");
  const [orderList, setOrderList] = useState<any>([]);
  const [orderFilterList, setOrderFilterList] = useState<any>([]);
  const [order, setOrder] = useState<any>([]);
  console.log("orderList", orderList)
  console.log("orderFilterList", orderFilterList)
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState<boolean>(false);
  const [orderStatusLists, setStatus] = useState([]);
  const [orderCounts, setOrderCounts] = useState<{ [key: string]: number }>({});
  const [totalOrders, setTotalOrders] = useState(0);
  const { showToast, toaster, clearToast } = useToast();
  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
  const [ordersListRoute, setRoute] = useState<any>([]);
  const [ordersListStore, setStore] = useState<any>([]);
console.log("Route", ordersListRoute)
console.log("Store", ordersListStore)

  const toggleModal = (orderId?: number) => {
    if (orderId !== undefined) {
      setSelectedOrderId(orderId); // Set selected order ID
      console.log("Selected Order ID:", orderId);
    }
    setIsModalOpen((prev) => !prev); // Toggle modal open/close
  };
  const toggleRouteModal = (orderId?: number) => {
    if (orderId !== undefined) {
      setSelectedOrderId(orderId); // Set selected order ID
      console.log("Selected Order ID:", orderId);
    }
    setIsRouteModalOpen((prev) => !prev); // Toggle modal open/close
  };
  useEffect(() => {
    if (toaster?.message) {
      if (toaster.status === true) {
        toast.success(toaster.message, { autoClose: 3000 });
      } else {
        toast.error(toaster.message, { autoClose: 3000 });
      }
      clearToast();
    }
  }, [toaster, clearToast]);

  const listFetchOrder = () => {
    return listOrder().then((res: any) => {
      if (!res || !res.data) return;

      const orders = res.data;

      // Calculate total orders
      setTotalOrders(orders.length);

      // Count orders per status
      const statusCounts: { [key: string]: number } = {};
      orders.forEach((order: any) => {
        const status = order.orderStatus?.name || "Unknown"; // Ensure status exists
        statusCounts[status] = (statusCounts[status] || 0) + 1;
      });

      setOrderCounts(statusCounts);
      setOrderList(orders);
      setOrderFilterList(orders);
    });
  };

  console.log("orderStatusLists index state", orderStatusLists)
  const handleDeleteOrder = () => {
    if (order) {
      // dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  useEffect(() => {

    listFetchOrder();
    OrderStatus().then((res: any) => {
      setStatus(res.data);
    });

    listStore().then((res: any) => {
      setStore(res.data);
    });

    listRoute().then((res: any) => {
      setRoute(res.data);
    });
  }, []);
  useEffect(() => {
    listOrder().then((res: any) => {
      setOrderList(res.data);
      setOrderFilterList(res.data);
    });
  }, []);

  const toggleTab = (tab: string, statusId: number | "all") => {
    console.log("Selected Tab:", tab, "Status ID:", statusId); // Debugging

    if (activeTab !== tab) {
      setActiveTab(tab);

      if (statusId === "all") {
        setOrderList([...orderFilterList]); // Ensure it's not filtered
      } else {
        const filteredOrders = orderFilterList.filter(
          (order: any) => order.orderStatus.id === statusId
        );
        setOrderList(filteredOrders);
      }
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleOrderClick = useCallback(
    (arg: any) => {
      const order = arg;
      setOrder({
        id: order.id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".orderCheckBox");
    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState<any>([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] =
    useState<boolean>(false);

  const deleteMultiple = () => {
    const checkall: any = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element: any) => {
      // dispatch(onDeleteOrder(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".orderCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleStatus = async () => {
    const data = { order_id: selectedOrderId, tracking_status: selectedStatus };
    console.log("data", data);
    const res: any = await UpdateOrderStatus(data);
    if (res.status === true) {
      showToast(res.message, res.status);
      listFetchOrder();
    } else {
      showToast(res.message, res.status);
    }
    toggleModal();
  };
  const handleRouteUpdate = async () => {
    const data = { order_id: selectedOrderId, tracking_status: selectedStatus };
    console.log("data", data);
    const res: any = await UpdateOrderStatus(data);
    if (res.status === true) {
      showToast(res.message, res.status);
      listFetchOrder();
    } else {
      showToast(res.message, res.status);
    }
    toggleModal();
  };
  // Column
  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              className="orderCheckBox form-check-input"
              value={cell.getValue()}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Order Id",
        accessorKey: "order_code_id",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = cell.row.original.delivery_date
          return (
            <>
              <Link to={`/orders/view/${cell.row.original.id}`} className="fw-medium link-primary">
                {cell.getValue()}
              </Link>
              <div>
                {handleValidDate(date)},
                <small className="text-muted">
                  {" "}
                  {handleValidTime(date)}
                </small>
              </div>
            </>
          );
        },
      },
      {
        header: "User",
        accessorKey: "users.name",
        enableColumnFilter: false,
      },

      {
        header: "Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
      },
      {
        header: "Route",
        accessorKey: "route.name",
        enableColumnFilter: false,
        cell: ({ row }) => row.original.route?.name || "_",
        
      },

      {
        header: "Route",
        accessorKey: "route.name",
        enableColumnFilter: false,
        cell: ({ row }: { row: { original: any } }) => {
          console.log("Row Data:", row.original); // Debugging
      
          const orderId = row.original.id;
          const routeName = row.original.route?.name || "_";
      
          return (
            <div className="d-flex align-items-center gap-2">
              <span className="badge fw-bold fs-7" onClick={() => toggleRouteModal(orderId)}>
                {routeName}
              </span>
              <Pencil size={14} className="text-muted cursor-pointer" onClick={() => toggleRouteModal(orderId)} />
            </div>
          );
        },
      },
      
      
      {
        header: "Store",
        accessorKey: "store.name",
        enableColumnFilter: false,
        cell: ({ row }) => {
          const storeName = row.original.store?.name || "_";
          const storeAddress = row.original.store?.address || "_";

          return (
            <div>
              <strong>{storeName}</strong>
              <br />
              <span className="text-muted">{storeAddress}</span>
            </div>
          );
        },
      },


      {
        header: "Address",
        accessorKey: "shipping_address",
        enableColumnFilter: false,
      },

      {
        header: "Status",
        accessorKey: "orderStatus.id",
        enableColumnFilter: false,
        cell: ({ row }) => {
          const statusId = row.original.orderStatus?.id;
          const statusName = row.original.orderStatus?.name;
          const orderId = row.original.id; // Capture orderId

          const statusMap = {
            1: { text: statusName, class: "bg-warning-subtle text-warning" },
            2: { text: statusName, class: "bg-secondary-subtle text-secondary" },
            3: { text: statusName, class: "bg-danger-subtle text-danger" },
            5: { text: statusName, class: "bg-primary-subtle text-primary" },
            6: { text: statusName, class: "bg-info-subtle text-info" },
            7: { text: statusName, class: "bg-success-subtle text-success" },
            8: { text: statusName, class: "bg-dark-subtle text-dark" },
            9: { text: statusName, class: "bg-light-subtle text-dark" },
          };

          const status = statusMap[statusId as keyof typeof statusMap] || {
            text: statusName || "Nill",
            class: "bg-warning-subtle text-warning",
          };

          return (
            <div className="d-flex align-items-center gap-2">
              <span
                className={`badge fw-bold fs-7 ${status.class}`}
                onClick={() => toggleModal(orderId)}
              >
                {status.text}
              </span>
              <Pencil
                size={14}
                className="text-muted cursor-pointer"
                onClick={() => toggleModal(orderId)}
              />
            </div>
          );
        },
      }



    ],
    [handleOrderClick, checkedAll]
  );

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time: any) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState<boolean>(false);

  document.title = " Paal Dappa | Admin panel";
  return (
    <div className="page-content">
      {/* Order Status Modal */}
      <OrderStatusModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        orderStatusLists={orderStatusLists}
        handleStatus={handleStatus}
      />
      <OrderRouteUpdate
        isOpen={isRouteModalOpen}
        toggle={toggleRouteModal}
        selectedRoute={selectedStatus}
        setSelectedRoute={setSelectedRoute}
        ordersListRoute={ordersListRoute}
        handleRouteUpdate={handleRouteUpdate}
      />
      {/* <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={orders}
      /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title="Orders" pageTitle="Ecommerce" />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      Order History ({orderList.length})
                    </h5>

                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <button
                        type="button"
                        className="btn btn-success add-btn"
                        id="create-btn"
                        onClick={() => { setIsEdit(false); toggle(); }}
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Download PDF
                      </button>{" "}
                      <button type="button" className="btn btn-info" onClick={() => setIsExportCSV(true)}>
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Download Excel
                      </button>
                      {" "}
                      {isMultiDeleteButton && <button className="btn btn-soft-danger"
                        onClick={() => setDeleteModalMulti(true)}
                      ><i
                        className="ri-delete-bin-2-line"></i></button>}
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">

                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab == "1" })}
                        onClick={() => {
                          toggleTab("all", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i> All Orders
                        <span className="badge bg-primary align-middle ms-1">{totalOrders}</span>
                      </NavLink>
                    </NavItem>


                    {orderStatusLists?.map((status: any, index: any) => (
                      <NavItem key={index}>
                        <NavLink
                          className={classnames({ active: activeTab === (index + 1).toString() })}
                          onClick={() => {
                            toggleTab(status.name, status.id);
                          }}
                          href="#"
                        >
                          <i className="ri-truck-line me-1 align-bottom"></i> {status.name}
                          <span className="badge bg-danger align-middle ms-1">
                            {orderCounts[status.name] || 0}
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>


                  {orderList?.length != 0 ? (
                    <TableContainer
                      columns={columns}
                      data={orderList || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-1 mt-0"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                    />
                  ) : (
                    // <Loader error={true} />
                    <div className="text-center text-danger fw-bold p-4">
                      <h5>No Results Found</h5>
                    </div>
                  )}
                </div>

                <ToastContainer closeButton={false} limit={1} />
              </CardBody>

            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Orders;
