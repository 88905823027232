import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Label,
  FormFeedback,
  Form,
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import "./ecommerce.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import defaultProductImage from "../../assets/images/product-default.png";

import classnames from "classnames";
import Dropzone from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";

import Flatpickr from "react-flatpickr";
import Select from "react-select";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  listBrand,
  listCategory,
  listFeatuer,
  listSpeacification,
  listVariant,
  updateProduct,
  viewProduct,
} from "./_requests";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { createSpecification } from "../../pages/Specification/_requests";
import { createVariant } from "../../pages/Variant/_requests";
import { createFeatures } from "../../pages/Features/_requests";
import { createCategory } from "../../pages/Category/_requests";
import { createBrand } from "../../pages/Brand/_requests";
import { useToast } from "../../Components/Common/ToastContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
interface FormattedData {
  variant: string;
  mrp: string;
  offer_value: number;
  offer_type: number;
  actual_amount: string;
  stock: number;
  sku: string;
}
type BrandOption = {
  label: any;
  value: any;
};
type CategoryOption = {
  label: any;
  value: any;
};
type SpecificationOption = {
  label: any;
  value: any;
};
type FeatureOption = {
  label: any;
  value: any;
};
interface Product {
  name?: string;
  short_description?: string;
  longDesc?: string;
  offerType?: string;
  offerValue?: string;
  category: string;
  brand: string;
  categoryId: string;
  brandId: string;
  width: string;
  weight: string;
  breath: string;
  length: string;
  hsn_code: string;
  status: string;
  cgst: string;
  sgst: string;
  igst: string;
  publishedDate: string;
  productDescription: string;
  visibility: string;
  tags: string[]; // or any other type depending on your structure
  variants: FormattedData[]; // Required: Array of variant data
}

interface FormattedData {
  variant_id: number;  
  variant_value: number;  
  mrp: string;
  offer_value: number;
  offer_type: number;
  actual_amount: string;
  stock: number;
  sku: string;
}

interface AvailableVariant {
  id: number;
  name: string;
}

interface VariantDetail {
  variant: number;
  value: string;
}

interface VariantData {
  [key: string]: string | number; // Dynamic keys for variant data
}

interface Result {
  variant: VariantDetail[];
  variantData: VariantData;
}

const EditProduct = () => {
  document.title = "Paal Dappa | Admin panel";
  const { userProfile } = useProfile();
  const { id } = useParams();
  const { showToast } = useToast();
  const history = useNavigate();
  const [product, setProduct] = useState<Product | null>(null); // Ensure product is not an array
  const [availableVariants, setAvailableVariants] = useState<any[]>([]);
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: product?.name || "",
      short_description: product?.short_description || "",
      productDescription: product?.longDesc || "",
      category: product?.categoryId || "",
      brand: product?.brandId || "",
      width: product?.width || "",
      weight: product?.weight || "",
      breath: product?.breath || "",
      length: product?.length || "",
      hsn_code: product?.hsn_code || "",
      status: product?.status || "",
      cgst: product?.cgst || "",
      sgst: product?.sgst || "",
      igst: product?.igst || "",
      publishedDate: product?.publishedDate || "",
      visibility: product?.visibility || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter a Product Name"),
      // weight: Yup.string().required("Please Enter Item Weight"),
      // width: Yup.string().required("Please Enter Item Width"),
      // length: Yup.string().required("Please Enter Item Length"),
      // breath: Yup.string().required("Please Enter Item Breath"),
      hsn_code: Yup.string().required("Please Enter HSN Code"),
      status: Yup.string().required("Please Select status"),
      cgst: Yup.string().required("Please Enter Cgst"),
      sgst: Yup.string().required("Please Enter Sgst"),
      igst: Yup.string().required("Please Enter Igst"),
      brand: Yup.string().required("Please Enter Product Brand"),
      category: Yup.string().required("Please Enter Product Category"),
    }),

    onSubmit: async (values) => {
      const formData = new FormData();

      // Append simple fields (strings, numbers)
      formData.append("name", values.name);
      formData.append("brand", values.brand);
      formData.append("category", values.category);
      formData.append("shortDescription", values.short_description);
      formData.append("productDescription", values.productDescription);
      formData.append("status", values.status);
      formData.append("publishDate", values.publishedDate);
      formData.append("publishStatus", values.status);
      formData.append("visibility", values.visibility);
      formData.append("igst", values.igst.toString());
      formData.append("sgst", values.sgst.toString());
      formData.append("cgst", values.cgst.toString());
      formData.append("hsn_code", values.hsn_code);
      formData.append("width", values.width.toString());
      formData.append("weight", values.weight.toString());
      formData.append("length", values.length.toString());
      formData.append("breath", values.breath.toString());
      formData.append("created_by", userProfile.userId);
      formData.append("tags", tags as any); // Ensure this is a string or valid data type
      if (variantData.length > 0) {
        formData.append("variantData", JSON.stringify(variantData)); // Serialize the entire array as a JSON string
      }

      // Append specification data as a JSON string
      if (Array.isArray(specification) && specification.length > 0) {
        formData.append("specification", JSON.stringify(specification)); // Serialize the array as a JSON string
      }

      // Append feature data as a JSON string
      if (Array.isArray(feature) && feature.length > 0) {
        formData.append("feature", JSON.stringify(feature)); // Serialize the array as a JSON string
      }

      // Handle single file (productImage)
      if (selectedImage) {
        formData.append("productImage", selectedImage);
      }

      // Handle multiple files (productGallery)
      if (selectedFiles && selectedFiles.length > 0) {
        // Filter out existing images (those that have an ID)
        const existingImages = selectedFiles.filter((file: any) => file.id);

        // New files are those without an ID (newly uploaded)
        const newFiles = selectedFiles.filter((file: any) => !file.id);

        // Append existing images as IDs to FormData
        if (existingImages.length > 0) {
          const existingImageIds = existingImages.map((image: any) => image.id);
          formData.append("existingImages", JSON.stringify(existingImageIds)); // Append as a JSON string of IDs
        }else{
          formData.append("existingImages", JSON.stringify([])); // 
        }

        // Append new files as actual files to FormData
        if (newFiles.length > 0) {
          newFiles.forEach((file: any) => {
            formData.append("productGallery", file); // Append each file
          });
        }
      }

      // Save new product
      const res: any = await updateProduct(formData, id);
      if (res.status === true) {
        showToast(res.message, res.status);
        validation.resetForm();
        history("/products");
      } else {
        showToast(res.message, res.status);
      }
    },
  });

  const dateFormat = (selectedDates: Date[]) => {
    // Ensure selectedDates is an array and has at least one date
    if (!Array.isArray(selectedDates) || selectedDates.length === 0) {
      return;
    }
    // Get the first selected date
    const dateString = selectedDates[0];
    // Parse the date string into a Date object
    const parsedDate = new Date(dateString);

    // Format the date to YYYY-MM-DDTHH:MM:SS format (ISO 8601 without timezone)
    const formattedDate = parsedDate.toISOString().split(".")[0]; // Removes the milliseconds and 'Z' (timezone)

    // Create a formatted date string
    // Set the formatted date in validation
    validation.setFieldValue("publishedDate", formattedDate);
  };
  // Example price options
  const priceOptions = [
    { value: "", label: "Select Offer Type" },
    { value: 1, label: "Percentage" },
    { value: 2, label: "Price" },
  ];
  useEffect(() => {
    listBrand().then((res: any) => {
      setAvailableBrand(res.data);
    });
    listSpeacification().then((res: any) => {
      setAvailableSpecification(res.data);
    });
    listVariant().then((res: any) => {
      setAvailableVariants(res.data);
    });
    console.log("availableVariants", availableVariants);
    listFeatuer().then((res: any) => {
      setAvailableFeature(res.data);
    });
    listCategory().then((res: any) => {
      setAvailableCategories(res.data);
    });
    viewProducts(id);
  }, []);
 // Function to retrieve dynamic variant and corresponding variantData
 const retrieveVariants = (
  formattedData: FormattedData[],
  availableVariants: AvailableVariant[]
): Result[] => {
  return formattedData.map((item) => {
    // // Split the variant string into components
    // const variantParts = item.variant.split(",");

    // // Initialize an object to hold the retrieved data
    const retrieved: Result = {
      variant: [],
      variantData: {},
    };

    // // Use a Set to keep track of processed variant IDs and combinations
    // const processedVariantIds = new Set<string>();

    // // Loop through the variant parts to retrieve original values
    // variantParts.forEach((part) => {
    //   const [variantId, value] = part.split("-");
    //   const id = parseInt(variantId);

    //   // Create a unique key for the variant combination
    //   const variantKey = `${id}-${value}`;

    //   // Check if this variant combination has already been processed
    //   if (!processedVariantIds.has(variantKey)) {
    //     processedVariantIds.add(variantKey); // Mark this variant combination as processed

    //     // Find the matching available variant
    //     const availableVariant = availableVariants.find((v) => v.id === id);
    //     if (availableVariant) {
    //       retrieved.variant.push({ variant: id, value: value });
    //     }
    //   }
    // });

    // // Build the variantData based on the retrieved variant values
    // retrieved.variant.forEach((v) => {
    //   const variantName = availableVariants.find(
    //     (av) => av.id === v.variant
    //   )?.name; // Get the variant name
    //   if (variantName) {
    //     retrieved.variantData[variantName] = v.value; // Add to variantData
    //   }
    // });

    // Add relevant pricing and stock data from formattedData
    retrieved.variantData.variant_id = item.variant_id;
    retrieved.variantData.variant_value = item.variant_value;
    retrieved.variantData.offer_type = item.offer_type;
    retrieved.variantData.offer_value = item.offer_value;
    retrieved.variantData.price = parseFloat(item.mrp);
    retrieved.variantData.stock = item.stock;
    retrieved.variantData.skuId = item.sku;

    return retrieved;
  });
};
  useEffect(() => {
    const result = retrieveVariants(product?.variants ?? [], availableVariants);

    // Create a Set to track unique variants
    const uniqueVariants = new Set<string>();
    const filteredVariants = result.flatMap((r) => {
      return r.variant.filter((v) => {
        const variantKey = `${v.variant}-${v.value}`;
        if (!uniqueVariants.has(variantKey)) {
          uniqueVariants.add(variantKey);
          return true; // Keep this variant
        }
        return false; // Skip this variant
      });
    });

    // Set the state with the retrieved data
    setVariantData(result.map((r) => r.variantData)); // Set variantData state
    setVariant(filteredVariants); // Set variants state without duplicates

    // Check the state after setting
    console.log(
      "Variant Data State:",
      result.map((r) => r.variantData)
    );
    console.log("Variants State:", filteredVariants);
  }, [availableVariants, product]);

  const viewProducts = async (id: any) => {
    try {
      const res: any = await viewProduct(id); // Replace with your actual API call

      const tagsArray = res.data?.tags
        ?.split(",")
        .map((tag: any) => tag.trim());

      // Set product-related state
      setProduct(res.data);
      setPreviewImage(res.data.icon);
      setFeature(res.data?.features);
      setSpecification(res.data?.specifications);
      setTags(tagsArray);
      // Function to fetch file size and type from an image URL
      const getFileSizeAndTypeFromImage = async (
        url: string
      ): Promise<{ size: number; type: string }> => {
        const response = await fetch(url);
        const blob = await response.blob(); // Get the image as a Blob
        const fileType = blob.type; // Get the MIME type from the Blob
        return { size: blob.size, type: fileType }; // Return size and type
      };

      // Process galleries and fetch file sizes and types
      const updatedGalleries = await Promise.all(
        res.data?.galleries?.map(async (file: any) => {
          const id = file.id;
          const fileName = file.icon.split("product/")[1]; // Extract filename
          const { size: fileSize, type: fileType } =
            await getFileSizeAndTypeFromImage(file.icon); // Fetch size and type dynamically
          return {
            id,
            name: fileName,
            preview: file.icon,
            formattedSize: formatBytes(fileSize), // Format the file size properly
            type: fileType, // Add the file type to the returned object
          };
        })
      );

      // Set galleries with updated file size
      setSelectedFiles(updatedGalleries);
    } catch (error) {
      console.error("Error viewing product:", error);
    }
  };

  const [customActiveTab, setcustomActiveTab] = useState<any>("1");

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isBrandModal, setIsBrandModal] = useState(false);
  const [newBrand, setNewBrand] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [BrandError, setBrandError] = useState("");

  const [availableBrand, setAvailableBrand] = useState<any[]>([]);

  const handleBrand = async () => {
    if (newBrand.trim() === "") {
      setBrandError("Please enter a brand name.");
      return;
    }

    const formData = new FormData();
    formData.append("name", newBrand.trim());
    if (selectedBrandImage) {
      formData.append("icon", selectedBrandImage);
    }
    formData.append("created_by", userProfile?.userId);
    await createBrand(formData).then((res: any) => {
      if (res.status === true) {
        toast.success(res.message, { autoClose: 3000 });
        listBrand().then((res: any) => {
          setAvailableBrand(res.data);
        });
        // Reset modal state
        setNewBrand("");
        setBrandError("");
        setIsBrandModal(false);
      } else {
        toast.error(res.message, { autoClose: 3000 });
      }
    });
  };
  const toggleBrandModal = () => setIsBrandModal(!isBrandModal);

  const [previewBrandImage, setPreviewBrandImage] = useState<string | null>(
    null
  ); // Image preview
  const [selectedBrandImage, setSelectedBrandImage] = useState<File | null>(
    null
  ); // Selected image

  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState<string[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      if (!tags.includes(inputValue)) {
        setTags([...tags, inputValue.trim()]);
      }
      setInputValue(""); // Clear input after adding tag
    }
  };

  const handleRemoveTag = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newVariant, setNewVariant] = useState("");
  const [varaiantError, setVaraiantError] = useState("");

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleSave = async () => {
    if (newVariant.trim()) {
      const data = {
        name: newVariant,
        created_by: userProfile.userId,
      };
      await createVariant(data).then((res: any) => {
        if (res.status === true) {
          toast.success(res.message, { autoClose: 3000 });
          listVariant().then((res: any) => {
            setAvailableVariants(res.data);
          });
          setIsModalOpen(false);
        } else {
          toast.error(res.message, { autoClose: 3000 });
        }
      });
    } else {
      setVaraiantError("Please enter a variant name.");
    }
  };

  const [variant, setVariant] = useState<VariantDetail[]>([]); // Ensure `variant` is initialized as an empty string
  const [variantData, setVariantData] = useState<any[]>([]); // Store the variant combinations

  const [isSpecificationModal, setIsSpecificationModal] = useState(false);
  const [specification, setSpecification] = useState([
    { specification: "", value: "" },
  ]);
  const [newSpecification, setNewSpecification] = useState("");
  const [specificationError, setSpecificationError] = useState("");
  const [selectedParentSpecification, setSelectedParentSpecification] =
    useState<SpecificationOption | null>(null);
  const [availableSpecification, setAvailableSpecification] = useState<any[]>(
    []
  );

  const handleSpecification = async () => {
    if (newSpecification.trim() === "") {
      setSpecificationError("Please enter a Specification name.");
      return;
    }

    // Save logic for Specification and parent Specification (e.g., update the available Specification)
    const data = {
      name: newSpecification,
      specification_id: selectedParentSpecification
        ? selectedParentSpecification.value
        : null,
      created_by: userProfile?.userId,
    };
    await createSpecification(data).then((res: any) => {
      if (res.status === true) {
        toast.success(res.message, { autoClose: 3000 });
        listSpeacification().then((res: any) => {
          setAvailableSpecification(res.data);
        });
        // Reset modal state
        setNewSpecification("");
        setSelectedParentSpecification(null);
        setSpecificationError("");
        setIsSpecificationModal(false);
      } else {
        toast.error(res.message, { autoClose: 3000 });
      }
    });
  };

  const toggleSpecificationModal = () =>
    setIsSpecificationModal(!isSpecificationModal);

  const handleSpecificationAddRow = () => {
    const lastSpecification = specification[specification.length - 1];
    // Add a new row with the same variant value as the last selected variant
    setSpecification([
      ...specification,
      { specification: lastSpecification.specification, value: "" },
    ]);
  };

  const handleSpecificationRemoveRow = (index: any) => {
    const updatedRows = specification.filter((_, i) => i !== index);
    setSpecification(updatedRows);
  };

  const handleSpecificationChange = (index: any, field: any, newValue: any) => {
    const updatedRows = specification.map((row, i) =>
      i === index ? { ...row, [field]: newValue } : row
    );
    setSpecification(updatedRows);
  };
  const [isFeatureModal, setIsFeatureModal] = useState(false);
  const [feature, setFeature] = useState([{ feature: "", value: "" }]);
  const [newFeature, setNewFeature] = useState("");
  const [featureError, setFeatureError] = useState("");
  const [selectedParentFeature, setSelectedParentFeature] =
    useState<FeatureOption | null>(null);
  const [availableFeature, setAvailableFeature] = useState<any[]>([]);

  const handleFeature = async () => {
    if (newFeature.trim() === "") {
      setFeatureError("Please enter a Feature name.");
      return;
    }

    // Save logic for Feature and parent Feature (e.g., update the available Feature)
    const data = {
      name: newFeature,
      featuer_id: selectedParentFeature ? selectedParentFeature.value : null,
      created_by: userProfile.userId,
    };

    await createFeatures(data).then((res: any) => {
      if (res.status === true) {
        toast.success(res.message, { autoClose: 3000 });
        listFeatuer().then((res: any) => {
          setAvailableFeature(res.data);
        });
        // Reset modal state
        setNewFeature("");
        setSelectedParentFeature(null);
        setFeatureError("");
        setIsFeatureModal(false);
      } else {
        toast.error(res.message, { autoClose: 3000 });
      }
    });
  };
  const toggleFeatureModal = () => setIsFeatureModal(!isFeatureModal);

  const handleFeatureAddRow = () => {
    const lastFeature = feature[feature.length - 1];
    // Add a new row with the same Feature value as the last selected Feature
    setFeature([...feature, { feature: lastFeature.feature, value: "" }]);
  };

  const handleFeatureRemoveRow = (index: any) => {
    const updatedRows = feature.filter((_, i) => i !== index);
    setFeature(updatedRows);
  };

  const handleFeatureChange = (index: any, field: any, newValue: any) => {
    const updatedRows = feature.map((row, i) =>
      i === index ? { ...row, [field]: newValue } : row
    );
    setFeature(updatedRows);
  };

  const [isCategoryModal, setIsCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const [selectedParentCategory, setSelectedParentCategory] =
    useState<CategoryOption | null>(null);
  const [availableCategories, setAvailableCategories] = useState<any[]>([]);

  const handleCategory = async () => {
    if (newCategory.trim() === "") {
      setCategoryError("Please enter a category name.");
      return;
    }

    // Save logic for category and parent category (e.g., update the available categories)
    const data = {
      name: newCategory,
      category_id: selectedParentCategory ? selectedParentCategory.value : null,
      created_by: userProfile.userId,
    };

    await createCategory(data).then((res: any) => {
      if (res.status === true) {
        toast.success(res.message, { autoClose: 3000 });
        listCategory().then((res: any) => {
          setAvailableCategories(res.data);
        });
        // Reset modal state
        setNewCategory("");
        setSelectedParentCategory(null);
        setCategoryError("");
        setIsCategoryModal(false);
      } else {
        toast.error(res.message, { autoClose: 3000 });
      }
    });
  };
  const toggleCategoryModal = () => setIsCategoryModal(!isCategoryModal);

  const handleAddRow = () => {
    const lastVariant = variant[variant.length - 1];
    // Add a new row with the same variant value as the last selected variant
    setVariant([...variant, { variant: lastVariant.variant, value: "" }]);
  };

  const handleRemoveRow = (index: any) => {
    const updatedRows = variant.filter((_, i) => i !== index);
    setVariant(updatedRows);
  };

  const handleCategoryChange = (index: any, field: any, newValue: any) => {
    const updatedRows = variant.map((row, i) =>
      i === index ? { ...row, [field]: newValue } : row
    );
    setVariant(updatedRows);
  };

  const handleAcceptedFiles = (files: any) => {
    const updatedFiles = files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    const totalFiles = selectedFiles.length + updatedFiles.length;

    if (totalFiles > 10) {
      const allowedFilesCount = 10 - selectedFiles.length;
      const filesToAdd = updatedFiles.slice(0, allowedFilesCount);

      // Show a toast notification for exceeding the limit
      toast.warn(`You can only upload up to 10 files.`);

      setSelectedFiles((prevFiles: any) => [...prevFiles, ...filesToAdd]);
    } else {
      setSelectedFiles((prevFiles: any) => [...prevFiles, ...updatedFiles]);
    }
  };

  const removeFile = (index: number) => {
    setSelectedFiles((prevFiles: any[]) => {
      // Create a shallow copy of the array and remove the file at the given index
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1); // Remove the file at the specific index
      return updatedFiles;
    });
  };

  useEffect(() => {
    //Remember to revoke the object URLs when you are done with them to avoid memory leaks:
    return () => {
      selectedFiles.forEach((file: any) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview); // Clean up
        }
      });
    };
  }, [selectedFiles]);
  console.log("selectedFiles", selectedFiles);

  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const productStatus = [
    {
      options: [
        { label: "Select Publish status", value: "" },
        { label: "Draft", value: 1 },
        { label: "Published", value: 2 },
        { label: "Scheduled", value: 3 },
      ],
    },
  ];

  const productVisibility = [
    {
      options: [
        { label: "Select product visibility", value: "" },
        { label: "Hidden", value: 1 },
        { label: "Public", value: 2 },
      ],
    },
  ];

  // image
  const [selectedImage, setSelectedImage] = useState<any>();
  const [previewImage, setPreviewImage] = useState<any>(null); // Image preview
  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: any
  ) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];

      // Store the file object in state
      field === "formImage" && setSelectedImage(file);
      field === "modalImage" && setSelectedBrandImage(file);

      // Optional: Generate a base64 preview (for image preview purposes)
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          field === "formImage" && setPreviewImage(e.target.result as string); // Store the base64 string for preview
          field === "modalImage" &&
            setPreviewBrandImage(e.target.result as string); // Store the base64 string for preview
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: "38px", // Customize the height to match Bootstrap's Input height
    }),
  };

   // Custom Quill modules for toolbar
   const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Header
      [{ font: [] }], // Font family
      [{ size: ["small", false, "large", "huge"] }], // Font size
      [{ color: [] }, { background: [] }], // Text color and background
      ["bold", "italic", "underline"], // Text style
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      [{ align: [] }], // Alignment
      ["link", "image"], // Insert links or images
      ["clean"], // Clear formatting
    ],
  };

  // Formats allowed by ReactQuill
  const quillFormats = [
    "header",
    "font",
    "size",
    "color",
    "background",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "link",
    "image",
  ];
  const scrollToError = (errors: any) => {
    const errorFields = Object.keys(errors);
    if (errorFields.length > 0) {
      const errorElement = document.querySelector(`[name="${errorFields[0]}"]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };
  const scrollToArrayError = (errors: any) => {
    const errorFields = Object.keys(errors);
    if (errorFields.length > 0) {
      const errorElement = document.querySelector(`[name="${errorFields[0]}"]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        // Handle nested array errors
        for (const field of errorFields) {
          if (Array.isArray(errors[field])) {
            const nestedErrorElement = document.querySelector(`[name="${field}[0]"]`);
            if (nestedErrorElement) {
              nestedErrorElement.scrollIntoView({ behavior: "smooth", block: "center" });
              break;
            }
          }
        }
      }
    }
  };

  const handleCombinationChange = (index: number, field: string, value: any) => {
    const updatedVariantData = [...variantData];
    updatedVariantData[index] = {
      ...updatedVariantData[index],
      [field]: value,
    };
    setVariantData(updatedVariantData);
  };

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
      <Container fluid>
        <BreadCrumb title="Update Product" pageTitle="Ecommerce" />
        <Form
          onSubmit={validation.handleSubmit}
        >
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Product Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="product-title-input"
                      placeholder="Enter product Name"
                      name="name"
                      value={validation.values.name || ""}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.name && validation.touched.name
                          ? true
                          : false
                      }
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div>
                    <Label>Product Description</Label>
                    <ReactQuill
                      theme="snow"
                      value={validation.values.productDescription}
                      onChange={(value: any) => validation.setFieldValue("productDescription", value)}
                      modules={quillModules}
                      formats={quillFormats}
                    />
                    {validation.errors.productDescription && validation.touched.productDescription ? (
                      <FormFeedback type="invalid">
                        {validation.errors.productDescription}
                      </FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>

              <Card className="pb-4">
                <CardHeader>
                  <h5 className="card-title mb-0">Product Gallery</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-4">
                    <h5 className="fs-14 mb-1">Product Image</h5>
                    <p className="text-muted">Add Product main Image.</p>
                    <div className="text-center">
                      <div className="position-relative d-inline-block">
                        <div className="position-absolute top-100 start-100 translate-middle">
                          <Label
                            htmlFor="brand-image-input"
                            className="mb-0"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="Select Image"
                          >
                            <div className="avatar-xs cursor-pointer">
                              <div className="avatar-title bg-light border rounded-circle text-muted">
                                <i className="ri-image-fill"></i>
                              </div>
                            </div>
                          </Label>
                          <Input
                            name="formImage"
                            className="form-control d-none"
                            id="brand-image-input"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => handleImageChange(e, "formImage")}
                            invalid={
                              validation.touched.image &&
                                validation.errors.image
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="avatar-lg">
                          <div className="avatar-title bg-light rounded">
                            <img
                              src={previewImage}
                              id="product-img"
                              alt=""
                              className="avatar-md h-auto"
                            />
                          </div>
                        </div>
                      </div>
                      {validation.errors.image && validation.touched.image ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.image}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div></div>
                  <h5 className="fs-14 mb-1">Product Gallery</h5>
                  <p className="text-muted">Add Product Gallery Images.</p>

                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <div className="mb-3 mt-5">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                          </div>
                          <h5>Drop files here or click to upload.</h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="list-unstyled mb-0" id="file-previews">
                    <div className="list-unstyled mb-0" id="file-previews">
                      {selectedFiles.map((file: any, index: number) => (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={index + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={file.name}
                                  src={
                                    file?.preview &&
                                      file?.type?.startsWith("video/")
                                      ? defaultProductImage // Use a default video thumbnail for video files
                                      : file.preview || defaultProductImage // Use preview or default product image
                                  }
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {file.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{file.formattedSize}</strong>
                                </p>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => removeFile(index)}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Brand</h5>
                </CardHeader>
                <CardBody>
                  <p className="text-muted mb-2">
                    {" "}
                    <Link
                      to="#"
                      className="float-end text-decoration-underline"
                      onClick={toggleBrandModal}
                    >
                      Add New
                    </Link>
                    Select product Brand
                  </p>

                  <select
                    className={`form-control ${validation.errors.brand && validation.touched.brand
                      ? "is-invalid"
                      : ""
                      }`}
                    id="price-select"
                    name="brand"
                    aria-placeholder="select offer type"
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    value={validation.values.brand}
                  >
                    <option value="">Select Brand</option>
                    {availableBrand.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>

                  {validation.touched.brand && validation.errors.brand ? (
                    <FormFeedback type="invalid">
                      {validation.errors.brand}
                    </FormFeedback>
                  ) : null}
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Categories</h5>
                </CardHeader>
                <CardBody>
                  <p className="text-muted mb-2">
                    {" "}
                    <Link
                      to="#"
                      className="float-end text-decoration-underline"
                      onClick={toggleCategoryModal}
                    >
                      Add New
                    </Link>
                    Select product category
                  </p>

                  <select
                    className={`form-control ${validation.errors.category && validation.touched.category
                      ? "is-invalid"
                      : ""
                      }`}
                    id="price-select"
                    name="category"
                    aria-placeholder="select offer type"
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    value={validation.values.category}
                  >
                    <option value="">Select Category</option>
                    {availableCategories.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>

                  {validation.touched.category && validation.errors.category ? (
                    <FormFeedback type="invalid">
                      {validation.errors.category}
                    </FormFeedback>
                  ) : null}
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Tags</h5>
                </CardHeader>
                <CardBody>
                  <div className="tag-input-container">
                    <div className="form-control d-flex flex-wrap align-items-center">
                      {tags.map((tag, index) => (
                        <span
                          key={index}
                          className="badge bg-primary me-2 mb-2"
                        >
                          {tag}
                          <span
                            className="ms-1 cursor-pointer"
                            onClick={() => handleRemoveTag(index)}
                          >
                            &times;
                          </span>
                        </span>
                      ))}
                      <input
                        type="text"
                        name="tags" // Add name attribute for Formik
                        placeholder="Enter tags"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        style={{ minWidth: "100px" }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Short Description</h5>
                </CardHeader>
                <CardBody>
                  <p className="text-muted mb-2">
                    Add short description for product
                  </p>
                  <textarea
                    className="form-control"
                    placeholder="Must enter minimum of a 100 characters"
                    value={validation.values.short_description}
                    onChange={(e) =>
                      validation.setFieldValue(
                        "short_description",
                        e.target.value
                      )
                    }
                    rows={3}
                  ></textarea>
                  {validation.errors.short_description && validation.touched.short_description ? (
                    <FormFeedback type="invalid">
                      {validation.errors.short_description}
                    </FormFeedback>
                  ) : null}
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Publish</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label
                      htmlFor="choices-publish-status-input"
                      className="form-label"
                    >
                      Status
                    </Label>
                    <select
                      className={`form-control ${validation.errors.status && validation.touched.status
                        ? "is-invalid"
                        : ""
                        }`}
                      id="price-select"
                      name="status"
                      aria-placeholder="select offer type"
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      value={validation.values.status}
                    >
                      {productStatus.map((item, key) => (
                        <React.Fragment key={key}>
                          {item.options.map((item, key) => (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          ))}
                        </React.Fragment>
                      ))}
                    </select>
                    {validation.touched.status && validation.errors.status ? (
                      <FormFeedback type="invalid">
                        {validation.errors.status}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label
                      htmlFor="choices-publish-visibility-input"
                      className="form-label"
                    >
                      Visibility
                    </Label>
                    <Input
                      name="visibility"
                      type="select"
                      className="form-select"
                      id="choices-publish-status-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.visibility || ""}
                    >
                      {productVisibility.map((item, key) => (
                        <React.Fragment key={key}>
                          {item.options.map((item, key) => (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          ))}
                        </React.Fragment>
                      ))}
                    </Input>
                    {validation.touched.visibility && validation.errors.visibility ? (
                      <FormFeedback type="invalid">
                        {validation.errors.visibility}
                      </FormFeedback>
                    ) : null}
                  </div>
                  {validation.values.status == 3 && (
                    <div>
                      <label
                        htmlFor="datepicker-publish-input"
                        className="form-label"
                      >
                        Publish Date & Time
                      </label>
                      <Flatpickr
                        name="publishedDate"
                        id="publishedDate-field"
                        className="form-control"
                        placeholder="Select a date"
                        options={{
                          enableTime: true,
                          altInput: true,
                          altFormat: "d M, Y, h:i K",
                          dateFormat: "d M, Y, h:i K",
                        }}
                        onChange={(selectedDates) => dateFormat(selectedDates)}
                        value={validation.values.publishedDate || ""}
                      />
                      {validation.touched.publishedDate &&
                        validation.errors.publishedDate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.publishedDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        Tax Info
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        Specifications
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleCustom("4");
                        }}
                      >
                        Features
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "5",
                        })}
                        onClick={() => {
                          toggleCustom("5");
                        }}
                      >
                        Variant
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>

                <CardBody>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="1">
                      <Row></Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="hsn-code-input"
                            >
                              HSN Code
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="hsn-code-input"
                              name="hsn_code"
                              placeholder="Enter HSN code"
                              value={validation.values.hsn_code || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.hsn_code &&
                                  validation.touched.hsn_code
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.hsn_code &&
                              validation.touched.hsn_code ? (
                              <FormFeedback type="invalid">
                                {validation.errors.hsn_code}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="hsn-code-input"
                            >
                              IGST
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="hsn-code-input"
                              name="igst"
                              placeholder="Enter igst percentage"
                              value={validation.values.igst || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.igst &&
                                  validation.touched.igst
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.igst &&
                              validation.touched.igst ? (
                              <FormFeedback type="invalid">
                                {validation.errors.igst}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="hsn-code-input"
                            >
                              CGST
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="hsn-code-input"
                              name="cgst"
                              placeholder="Enter cgst percentage"
                              value={validation.values.cgst || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.cgst &&
                                  validation.touched.cgst
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.cgst &&
                              validation.touched.cgst ? (
                              <FormFeedback type="invalid">
                                {validation.errors.cgst}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="hsn-code-input"
                            >
                              SGST
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="hsn-code-input"
                              name="sgst"
                              placeholder="Enter sgst percentage"
                              value={validation.values.sgst || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.sgst &&
                                  validation.touched.sgst
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.sgst &&
                              validation.touched.sgst ? (
                              <FormFeedback type="invalid">
                                {validation.errors.sgst}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="3">
                      <Row>
                        <FormGroup>
                          <p className="mb-2 col-lg-4">
                            {" "}
                            <Link
                              to="#"
                              className="float-end text-decoration-underline"
                              onClick={toggleSpecificationModal}
                            >
                              Add New
                            </Link>
                            Product Specifications
                          </p>
                          <TransitionGroup>
                            {specification.map((row, index) => (
                              <CSSTransition
                                key={index}
                                timeout={300}
                                classNames="variant-row"
                              >
                                <Row className="mb-2 align-items-center">
                                  <Col lg={4}>
                                    <Select
                                      className="mb-2"
                                      value={{
                                        label:
                                          availableSpecification.find(
                                            (spec) =>
                                              spec.id === row.specification // Change from `value` to `id`
                                          )?.name || "", // Use the correct label here
                                        value: row.specification,
                                      }}
                                      onChange={(selectedOption: any) => {
                                        handleSpecificationChange(
                                          index,
                                          "specification",
                                          selectedOption?.value // Use `value` from selected option
                                        );
                                      }}
                                      options={availableSpecification.map(
                                        (spec) => ({
                                          label: spec.name,
                                          value: spec.id,
                                        })
                                      )}
                                      placeholder="Select Specification"
                                      styles={customStyles} // Optional: custom styles to match Bootstrap Input styling
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Input
                                      type="text"
                                      className="mb-2"
                                      placeholder="Enter value"
                                      value={row.value}
                                      onChange={(e) =>
                                        handleSpecificationChange(
                                          index,
                                          "value",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Button
                                      color="primary"
                                      className="me-2"
                                      onClick={handleSpecificationAddRow}
                                      disabled={
                                        index !== specification.length - 1
                                      }
                                    >
                                      +
                                    </Button>

                                    {specification.length > 1 && (
                                      <Button
                                        color="danger"
                                        onClick={() =>
                                          handleSpecificationRemoveRow(index)
                                        }
                                      >
                                        Remove
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </CSSTransition>
                            ))}
                          </TransitionGroup>
                        </FormGroup>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="4">
                      <Row>
                        <FormGroup>
                          <p className="mb-2 col-lg-4">
                            <Link
                              to="#"
                              className="float-end text-decoration-underline"
                              onClick={toggleFeatureModal}
                            >
                              Add New
                            </Link>
                            Product Feature
                          </p>
                          <TransitionGroup>
                            {feature.map((row, index) => (
                              <CSSTransition
                                key={index}
                                timeout={300}
                                classNames="variant-row"
                              >
                                <Row className="mb-2 align-items-center">
                                  <Col lg={4}>
                                    <Select
                                      className="mb-2"
                                      value={{
                                        label:
                                          availableFeature.find(
                                            (feat) => feat.id === row.feature // Change from `feature.value` to `feat.id`
                                          )?.name || "", // Use the correct property for the label
                                        value: row.feature,
                                      }}
                                      onChange={(selectedOption: any) => {
                                        handleFeatureChange(
                                          index,
                                          "feature",
                                          selectedOption?.value // Ensure selected value is passed correctly
                                        );
                                      }}
                                      options={availableFeature.map((feat) => ({
                                        label: feat.name,
                                        value: feat.id,
                                      }))}
                                      placeholder="Select Feature"
                                      styles={customStyles} // Optional: custom styles to match Bootstrap Input styling
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Input
                                      type="text"
                                      className="mb-2"
                                      placeholder="Enter value"
                                      value={row.value}
                                      onChange={(e) =>
                                        handleFeatureChange(
                                          index,
                                          "value",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Button
                                      color="primary"
                                      className="me-2"
                                      onClick={handleFeatureAddRow}
                                      disabled={index !== feature.length - 1}
                                    >
                                      +
                                    </Button>
                                    {feature.length > 1 && (
                                      <Button
                                        color="danger"
                                        onClick={() =>
                                          handleFeatureRemoveRow(index)
                                        }
                                      >
                                        Remove
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </CSSTransition>
                            ))}
                          </TransitionGroup>
                        </FormGroup>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="5">

                      <Row>
                        {/* Dynamic table for combinations */}
                        {/* {variantData.length > 0 && ( */}
                        <div className="table-responsive mt-3">
                          <Table bordered className="table">
                            <thead>
                              <tr>
                                <th className="text-center w-10">S.No</th>
                                <th className="text-center w-200">Variant</th> {/* Increased column size */}
                                <th className="text-center w-150">Value</th>
                                <th className="text-center w-150">Offer Type</th>
                                <th className="text-center w-150">Offer Value</th>
                                <th className="text-center w-150">Price</th>
                                <th className="text-center w-150">Stock</th>
                                <th className="text-center w-150">Sku Id</th>
                                <th className="text-center w-150">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {variantData.map((combo, index) => (
                                <tr key={index}>
                                  <td className="text-center">{index + 1}</td>
                                  <td>
                                    <select
                                      className={`form-control ${validation.errors.variantData?.[index]?.variant_id && validation.touched.variantData?.[index]?.variant_id ? "is-invalid" : ""}`}
                                      name={`variantData[${index}].variant_id`}
                                      value={combo.variant_id}
                                      onChange={(e) => handleCombinationChange(index, "variant_id", e.target.value)}
                                    >
                                      <option value="">Select Variant</option>
                                      {availableVariants.map((variant) => (
                                        <option key={variant.id} value={variant.id}>
                                          {variant.name}
                                        </option>
                                      ))}
                                    </select>
                                    {validation.errors.variantData?.[index]?.variant_id && validation.touched.variantData?.[index]?.variant_id && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.variantData[index].variant_id}
                                      </FormFeedback>
                                    )}
                                    <p className="col-lg-6">
                                      <Link to="#" className="float-end text-decoration-underline" onClick={toggleModal}>
                                        Add New
                                      </Link>
                                    </p>
                                  </td>
                                  <td>
                                    <Input
                                      type="text"
                                      name={`variantData[${index}].variant_value`}
                                      value={combo.variant_value}
                                      onChange={(e) => handleCombinationChange(index, "variant_value", e.target.value)}
                                      className={`form-control form-control-sm ${validation.errors.variantData?.[index]?.variant_value && validation.touched.variantData?.[index]?.variant_value ? "is-invalid" : ""}`}
                                    />
                                    {validation.errors.variantData?.[index]?.variant_value && validation.touched.variantData?.[index]?.variant_value && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.variantData[index].variant_value}
                                      </FormFeedback>
                                    )}
                                  </td>
                                  <td>
                                    <select
                                      className={`form-control ${validation.errors.variantData?.[index]?.offer_type && validation.touched.variantData?.[index]?.offer_type ? "is-invalid" : ""}`}
                                      name={`variantData[${index}].offer_type`}
                                      value={combo.offer_type}
                                      onChange={(e) => handleCombinationChange(index, "offer_type", e.target.value)}
                                    >
                                      {priceOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                    {validation.errors.variantData?.[index]?.offer_type && validation.touched.variantData?.[index]?.offer_type && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.variantData[index].offer_type}
                                      </FormFeedback>
                                    )}
                                  </td>
                                  <td>
                                    <Input
                                      type="text"
                                      name={`variantData[${index}].offer_value`}
                                      value={combo.offer_value}
                                      onChange={(e) => handleCombinationChange(index, "offer_value", e.target.value)}
                                      className={`form-control form-control-sm ${validation.errors.variantData?.[index]?.offer_value && validation.touched.variantData?.[index]?.offer_value ? "is-invalid" : ""}`}
                                    />
                                    {validation.errors.variantData?.[index]?.offer_value && validation.touched.variantData?.[index]?.offer_value && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.variantData[index].offer_value}
                                      </FormFeedback>
                                    )}
                                  </td>
                                  <td>
                                    <Input
                                      type="number"
                                      name={`variantData[${index}].price`}
                                      value={combo.price}
                                      onChange={(e) => handleCombinationChange(index, "price", e.target.value)}
                                      className={`form-control form-control-sm ${validation.errors.variantData?.[index]?.price && validation.touched.variantData?.[index]?.price ? "is-invalid" : ""}`}
                                    />
                                    {validation.errors.variantData?.[index]?.price && validation.touched.variantData?.[index]?.price && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.variantData[index].price}
                                      </FormFeedback>
                                    )}
                                  </td>
                                  <td>
                                    <Input
                                      type="number"
                                      name={`variantData[${index}].stock`}
                                      value={combo.stock}
                                      onChange={(e) => handleCombinationChange(index, "stock", e.target.value)}
                                      className={`form-control form-control-sm ${validation.errors.variantData?.[index]?.stock && validation.touched.variantData?.[index]?.stock ? "is-invalid" : ""}`}
                                    />
                                    {validation.errors.variantData?.[index]?.stock && validation.touched.variantData?.[index]?.stock && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.variantData[index].stock}
                                      </FormFeedback>
                                    )}
                                  </td>
                                  <td>
                                    <Input
                                      type="text"
                                      name={`variantData[${index}].skuId`}
                                      value={combo.skuId}
                                      onChange={(e) => handleCombinationChange(index, "skuId", e.target.value)}
                                      className={`form-control form-control-sm ${validation.errors.variantData?.[index]?.skuId && validation.touched.variantData?.[index]?.skuId ? "is-invalid" : ""}`}
                                    />
                                    {validation.errors.variantData?.[index]?.skuId && validation.touched.variantData?.[index]?.skuId && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.variantData[index].skuId}
                                      </FormFeedback>
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      color="primary"
                                      className="me-2"
                                      onClick={handleAddRow}
                                      disabled={index !== variantData.length - 1}
                                    >
                                      +
                                    </Button>
                                    {variantData.length > 1 && (
                                      <Button color="danger" onClick={() => handleRemoveRow(index)}>
                                        Remove
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                          </Table>
                        </div>
                        {/* // )} */}
                      </Row>

                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="text-end mb-3">
            <button type="submit" className="btn btn-success w-sm">
              Submit
            </button>
          </div>
        </Form>
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Add New Variant</ModalHeader>
          <ModalBody>
            <Label for="variantName">Variant Name</Label>
            <Input
              type="text"
              id="variantName"
              placeholder="Enter variant name"
              value={newVariant}
              onChange={(e) => {
                setNewVariant(e.target.value);
                setVaraiantError("");
              }}
            />
            {(varaiantError || newVariant === "") && (
              <span className="text-danger">{varaiantError}</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isCategoryModal} toggle={toggleCategoryModal}>
          <ModalHeader toggle={toggleCategoryModal}>
            Add New Category
          </ModalHeader>
          <ModalBody>
            <div>
              <Label for="categoryName">Category Name</Label>
              <Input
                type="text"
                id="categoryName"
                placeholder="Enter category name"
                value={newCategory}
                onChange={(e) => {
                  setNewCategory(e.target.value);
                  setCategoryError("");
                }}
              />
              {/* Display error message */}
              {(categoryError || newCategory === "") && (
                <span className="text-danger">{categoryError}</span>
              )}
            </div>

            <Label className="mt-3">Parent Category (Optional)</Label>
            <Select
              value={selectedParentCategory}
              onChange={(option: any) =>
                setSelectedParentCategory(option as CategoryOption)
              }
              options={availableCategories.map((spec) => ({
                label: spec.name,
                value: spec.id,
              }))}
              placeholder="Select Parent Category "
              isClearable
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleCategoryModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleCategory}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isSpecificationModal} toggle={toggleSpecificationModal}>
          <ModalHeader toggle={toggleSpecificationModal}>
            Add New Specification
          </ModalHeader>
          <ModalBody>
            <div>
              <Label for="SpecificationName">Specification Name</Label>
              <Input
                type="text"
                id="SpecificationName"
                placeholder="Enter specification name"
                value={newSpecification}
                onChange={(e) => {
                  setNewSpecification(e.target.value);
                  setSpecificationError("");
                }}
              />
              {/* Display error message */}
              {(specificationError || newSpecification === "") && (
                <span className="text-danger">{specificationError}</span>
              )}
            </div>

            <Label className="mt-3">Parent Specification (Optional)</Label>
            <Select
              value={selectedParentSpecification}
              onChange={(option: any) =>
                setSelectedParentSpecification(option as SpecificationOption)
              }
              options={availableSpecification.map((spec) => ({
                label: spec.name,
                value: spec.id,
              }))}
              placeholder="Select Parent Specification "
              isClearable
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleSpecificationModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleSpecification}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isFeatureModal} toggle={toggleFeatureModal}>
          <ModalHeader toggle={toggleFeatureModal}>Add New Feature</ModalHeader>
          <ModalBody>
            <div>
              <Label for="FeatureName">Feature Name</Label>
              <Input
                type="text"
                id="FeatureName"
                placeholder="Enter feature name"
                value={newFeature}
                onChange={(e) => {
                  setNewFeature(e.target.value);
                  setFeatureError("");
                }}
              />
              {/* Display error message */}
              {(featureError || newFeature === "") && (
                <span className="text-danger">{featureError}</span>
              )}
            </div>

            <Label className="mt-3">Parent Feature (Optional)</Label>
            <Select
              value={selectedParentFeature}
              onChange={(option: any) =>
                setSelectedParentFeature(option as FeatureOption)
              }
              options={availableFeature.map((spec) => ({
                label: spec.name,
                value: spec.id,
              }))}
              placeholder="Select Parent Feature "
              isClearable
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleFeatureModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleFeature}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isBrandModal} toggle={toggleBrandModal}>
          <ModalHeader toggle={toggleBrandModal}>Add Brand</ModalHeader>
          <ModalBody>
            <div className="text-center">
              <div className="position-relative d-inline-block">
                <div>
                  Brand Logo <span className="text-danger">*</span>
                </div>
                <div className="position-absolute  bottom-0 end-0">
                  <Label htmlFor="product-image-input" className="mb-0">
                    <div className="avatar-xs cursor-pointer">
                      <div className="avatar-title bg-light border rounded-circle text-muted">
                        <i className="ri-image-fill"></i>
                      </div>
                    </div>
                  </Label>
                  <Input
                    name="brandImage"
                    className="form-control d-none"
                    id="product-image-input"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleImageChange(e, "modalImage")}
                  />
                </div>
                <div className="avatar-lg p-1">
                  <div className="avatar-title bg-light rounded-circle">
                    <img
                      src={previewBrandImage || dummyImg}
                      alt="dummyImg"
                      id="customer-img"
                      className="avatar-md rounded-circle object-fit-cover"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Label for="BrandName">Brand Name</Label>
              <Input
                type="text"
                id="BrandName"
                placeholder="Enter brand name"
                value={newBrand}
                onChange={(e) => {
                  setNewBrand(e.target.value);
                  setBrandError(""); // Clear error when typing
                }}
              />
              {/* Display error message */}
              {BrandError && <span className="text-danger">{BrandError}</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleBrandModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleBrand}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  );
};

export default EditProduct;
