/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import TableContainer from "../../Components/Common/TableContainer";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { createStore, deleteStoreById, ListStore, updateStore } from "./_requests";

const Store = (data: any) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [Store, setStore] = useState<any>([]);
  const [Roles, setRoles] = useState<any>([]);
  const [Storeaccess, setStoreaccess] = useState<any>([]);
  const { userProfile } = useProfile();
  const [modal, setModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [StoreToDeleteId, setStoreToDeleteId] = useState<string>("");
  const [StoreToDeleteName, setStoreToDeleteName] = useState<string>("");
  const [Duplicate, setDuplicate] = useState<any>("");
  const [selectedImage, setSelectedImage] = useState<any>();
  const [Email, setEmail] = useState("");
  const toggle = useCallback(() => {
    setModal(!modal);
    setDuplicate("");
  }, [modal]);

  useEffect(() => {
    // getCRUD(userProfile?.role, ).then((res: any) => {
    //   setStoreaccess(res.Data[0]);
    // });
  }, []);


  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    fetchStore();
  }, []);

  const fetchStore = () => {
    ListStore().then((res: any) => {
      setStore(res.data);
    });
  };



  // Delete Data
  const onClickDelete = useCallback(
    (Store: any) => {
      setStoreToDeleteName(Store.name);
      setStoreToDeleteId(Store.id);

      setStoreaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.remove === 1) {
          toggleDeleteModal();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [setStoreToDeleteName, setStoreToDeleteId, toggleDeleteModal]
  );

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      address: "",
      city: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values: any) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("address", values.address);
        formData.append("city", values.city);
        formData.append(isEdit ? "updated_by" : "created_by" , userProfile?.userId);

        if (selectedImage) {
          formData.append(isEdit ? "profileNew" : "profile" , selectedImage);
          console.log("selectedImage", selectedImage);
        }
        if (isEdit) {
          await updateStore(formData, values.id);
          toast.success("Store updated Successfully", { autoClose: 3000 });
        } else {
          await createStore(formData);
          toast.success("Store created Successfully", { autoClose: 3000 });
        } 

        fetchStore();
        validation.resetForm();
        setSelectedImage("");
        toggle();
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong", { autoClose: 3000 });
      }
    },
  });

  const confirmDelete = async (StoreId: any) => {
    await deleteStoreById(StoreId ,userProfile?.userId);
    toggleDeleteModal();
    toast.success("Store deleted Successfully", { autoClose: 3000 });
    fetchStore();
  };

  // Update Data
  const handleStoreClick = useCallback(
    (Store: any) => {
      validation.setValues({
        id: Store.id,
        name: Store.name,
        city: Store.city,
        address: Store.address,
      });
      setPreviewImage(Store.profile);
      setIsEdit(true);

      setStoreaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.edit === 1) {
        toggle();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [toggle, validation]
  );

  const [previewImage, setPreviewImage] = useState<string | null>(null); // Optional for preview

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];

      // Store the file object in state
      setSelectedImage(file);

      // Optional: Generate a base64 preview (for image preview purposes)
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setPreviewImage(e.target.result as string); // Store the base64 string for preview
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // const DuplicateCheck = (val: any) => {
  //   try {
  //     checkDuplicateEmail(val).then((response: any) => {
  //       if (isEdit) {
  //         if (response?.isDuplicate === true && Email !== val) {
  //           setDuplicate("Email already exists");
  //         } else {
  //           setDuplicate("");
  //         }
  //       } else {
  //         if (response?.isDuplicate === true) {
  //           setDuplicate("Email already exist");
  //         } else {
  //           setDuplicate("");
  //         }
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error checking for duplicate role:", error);
  //   }
  // };

  // Stores Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={cell.row.original.profile}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to="/apps-ecommerce-product-details"
                    className="text-body"
                  >
                    {" "}
                    {cell.getValue()}
                  </Link>
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        header: "City",
        accessorKey: "city",
        enableColumnFilter: false,
      },
      {
        header: "Address",
        accessorKey: "address",
        enableColumnFilter: false,
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const StoreData = cellProps.row.original;
                    handleStoreClick(StoreData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const StoreData = cellProps.row.original;
                    onClickDelete(StoreData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleStoreClick, onClickDelete]
  );

  const addStore = () => {
    // if (Storeaccess?.add === 1) {
    setIsEdit(false);
    setPreviewImage("");
    setSelectedImage("");
    toggle();
    validation.setValues({ name: "", email: "", roleId: "", password: "" });
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Store" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="StoreList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Store List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          id="create-btn"
                          onClick={addStore}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Store
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Store || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Store..."
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Store" : "Add Store"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div>
                              Profile (Optional)
                            </div>
                            <div className="position-absolute  bottom-0 end-0">
                              <Label
                                htmlFor="customer-image-input"
                                className="mb-0"
                              >
                                <div className="avatar-xs cursor-pointer">
                                  <div className="avatar-title bg-light border rounded-circle text-muted">
                                    <i className="ri-image-fill"></i>
                                  </div>
                                </div>
                              </Label>
                              <Input
                                name="formImage"
                                className="form-control d-none"
                                id="customer-image-input"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={handleImageChange}
                              />
                            </div>
                            <div className="avatar-lg p-1">
                              <div className="avatar-title bg-light rounded-circle">
                                <img
                                  src={previewImage || dummyImg}
                                  alt="dummyImg"
                                  id="customer-img"
                                  className="avatar-md rounded-circle object-fit-cover"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Storename-field"
                            className="form-label"
                          >
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            id="Storename-field"
                            className="form-control"
                            placeholder="Enter  Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
           
                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Storename-field"
                            className="form-label"
                          >
                            City <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="city"
                            id="Storename-field"
                            className="form-control"
                            placeholder="Enter city"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.city || ""}
                            invalid={
                              validation.touched.city &&
                              validation.errors.city
                                ? true
                                : false
                            }
                          />
                          {validation.touched.city &&
                          validation.errors.city ? (
                            <FormFeedback type="invalid">
                              {validation.errors.city}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Storename-field"
                            className="form-label"
                          >
                            Address <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="address"
                            id="Storename-field"
                            className="form-control"
                            placeholder="Enter address"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </div>
                    
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>

                          <button
                            type="submit"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            disabled={Duplicate}
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add Store"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the Store{" "}
              <strong>{StoreToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={toggleDeleteModal}
              >
                Cancel
              </Button>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={() => {
                  confirmDelete(StoreToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Store;


