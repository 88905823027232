import { useProfile } from "../Components/Hooks/UserHooks";
import { getrolewisesidemenu } from "../pages/Roles/_requests-roleaccess";
import React, { useEffect, useState } from "react";

const Navdata = () => {
  const { userProfile } = useProfile();
  const currentRole = userProfile?.roleId;
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [dynamicStates, setDynamicStates] = useState<{ [key: string]: boolean }>({});
  const [groupedItems, setGroupedItems] = useState<{ [key: string]: any[] }>({});
  const [statesInitialized, setStatesInitialized] = useState(false);

// Function to fetch and process the side menu items
const fetchAndSetSideMenu = async (role: any) => {
  try {
    const response = await getrolewisesidemenu(role);
    const sidemenuitems = response?.data || [];
    
    const groupedItems: { [key: string]: any[] } = {};
    sidemenuitems.forEach((item: any) => {
      const titleName = item.module.title?.name || "Other";
      if (!groupedItems[titleName]) {
        groupedItems[titleName] = [];
      }
      groupedItems[titleName].push({
        id: item.module.name,
        label: item.module.name,
        icon: item.module.icon,
        titleicon: item.module.title?.icon,
        link: `/${item.module.link}`,
        parentId: titleName,
      });
    });

    const initialDynamicStates: { [key: string]: boolean } = {};
    Object.keys(groupedItems).forEach((key) => {
      initialDynamicStates[key] = false;
    });

    setDynamicStates(initialDynamicStates);
    setGroupedItems(groupedItems);
    setStatesInitialized(true);
  } catch (error) {
    console.error("Error fetching sidemenu:", error);
  }
};

useEffect(() => {
  if (currentRole) {
    fetchAndSetSideMenu(currentRole);
  }
}, [currentRole]);



  useEffect(() => {
    if (statesInitialized) {
      const menuItems = Object.keys(groupedItems).map((key:any) => {
        const firstItem = groupedItems[key]?.[0]; // Assuming at least one item in each group
        const icon =  firstItem?.titleicon || "mdi mdi-folder"; // Default icon if not found
        
        return {
          id: key,
          label: key,
          icon ,
          link: "/#",
          stateVariables: dynamicStates[key],
          click: generateItemClickHandler(key),
          subItems: groupedItems[key],
        };
      });
      setMenuItems(menuItems);
    }

  }, [statesInitialized, dynamicStates, groupedItems]);

  const generateItemClickHandler = (label: string) => (e: any) => {
    e.preventDefault();
    setDynamicStates((prevStates) => {
      const newState = !prevStates[label];
      const updatedStates = { ...prevStates, [label]: newState };
      return updatedStates;
    });
    updateIconSidebar(e);
  };

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }
  return (
    <React.Fragment>
      {menuItems}
    </React.Fragment>
  );
};

export default Navdata;

