import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";

import TableContainer from "../../Components/Common/TableContainer";
import {
  checkDuplicate,
  createPlan,
  deletePlan,
  listPlan,
  updatePlan,
} from "./_requests";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { listPlanFeatures } from "../../pages/Plan Features/_requests";
import { listPlanType } from "../../pages/Plan Type/_requests";

const Plan = (data: any) => {
  const { userProfile } = useProfile();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [Plan, setPlan] = useState<any>([]);
  const [plantype, setPlanType] = useState<any>([]);
  const [features, setFeatures] = useState<any>([]);
  const [Planaccess, setrolecaccess] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [PlanToDeleteId, setPlanToDeleteId] = useState<string>("");
  const [PlanToDeleteName, setPlanToDeleteName] = useState<string>("");
  const [Duplicate, setDuplicate] = useState<any>("");
  const [name, setName] = useState("");
  const toggle = useCallback(() => {
    setModal(!modal);
    setDuplicate("");
  }, [modal]);

  useEffect(() => {
    // getCRUD(PlanProfile?.role, Data).then((res: any) => {
    //   setrolecaccess(res.Data[0]);
    // });
  }, []);

  const toggleDeleteModal = useCallback(() => {
    setDeleteModalOpen(!deleteModalOpen);
  }, [deleteModalOpen]);

  useEffect(() => {
    listPlanType().then((res: any) => {
      setPlanType(res.data);
    });

    listPlanFeatures().then((res: any) => {
      setFeatures(res.data);
    });

    fetchPlan();
  }, []);

  const fetchPlan = () => {
    listPlan().then((res: any) => {
      setPlan(res.data);
    });
  };

  // useEffect(() => {
  //   ListRole(Type, TypeId).then((res: any) => {
  //     setRoles(res);
  //   });
  // }, [modal, deleteModalOpen]);

  // Delete Data
  const onClickDelete = useCallback(
    (Plan: any) => {
      setPlanToDeleteName(Plan.name);
      setPlanToDeleteId(Plan.id);

      setrolecaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.remove === 1) {
        toggleDeleteModal();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [setPlanToDeleteName, setPlanToDeleteId, toggleDeleteModal]
  );

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      name: "",
      Plan_type_id: "",
      Plan_feature_id: "",
      validity: "",
      amount: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      Plan_type_id: Yup.string().required("Plan type is required"),
      Plan_feature_id: Yup.string().required("Plan features is required"),
      validity: Yup.string().required("Validity is required"),
      amount: Yup.string().required("Amount is required"),
    }),
    onSubmit: async (values: any) => {
      const userId = userProfile?.userId; // Replace this with dynamic user ID if available (e.g., from auth context)

      const planData = {
        name: values.name,
        Plan_type_id: values.Plan_type_id,
        Plan_feature_id: values.Plan_feature_id,
        validity: values.validity,
        amount: values.amount,
        ...(isEdit ? { updated_by: userId } : { created_by: userId }), // Dynamically add created_by or updated_by
      };

      try {
        if (isEdit) {
          await updatePlan(planData, values.id);
          toast.success("Plan updated successfully", { autoClose: 3000 });
        } else {
          await createPlan(planData);
          toast.success("Plan created successfully", { autoClose: 3000 });
        }
        fetchPlan();
        validation.resetForm(); // Reset form after success
      } catch (error) {
        toast.error("An error occurred, please try again.", {
          autoClose: 3000,
        });
      }

      toggle(); // Close modal
    },
  });

  const confirmDelete = async (PlanId: any) => {
    const res: any = await deletePlan(PlanId);
    toast.success(res.message, { autoClose: 3000 });
    toggleDeleteModal();
    fetchPlan();
  };

  // Update Data
  const handlePlanClick = useCallback(
    (Plan: any) => {
      validation.setValues({
        id: Plan.id,
        name: Plan.name,
        Plan_type_id: Plan.Plan_type_id,
        Plan_feature_id: Plan.Plan_feature_id,
        validity: Plan.validity,
        amount: Plan.amount,
      });
      setName(Plan.name);
      setIsEdit(true);

      setrolecaccess((prevDepAccess: any) => {
        // if (prevDepAccess?.edit === 1) {
        toggle();
        // } else {
        //   toast.error("You don't have permission to access this page", {
        //     position: "top-right",
        //     style: {
        //       backgroundColor: "#9b2313",
        //       color: "#c7c7d7",
        //       fontSize: "14px",
        //     },
        //     closeButton: false,
        //   });
        // }
        return prevDepAccess;
      });
    },
    [toggle, validation]
  );

  const DuplicateCheck = (val: any) => {
    try {
      if (val.length <= 3) {
        checkDuplicate(val).then((response: any) => {
          if (isEdit) {
            if (response?.isDuplicate === true && name !== val) {
              setDuplicate("Plan already exists");
            } else {
              setDuplicate("");
            }
          } else {
            if (response?.isDuplicate === true) {
              setDuplicate("Plan already exist");
            } else {
              setDuplicate("");
            }
          }
        });
      }
    } catch (error) {
      console.error("Error checking for duplicate role:", error);
    }
  };

  // Plans Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        id: "sno",
        enableColumnFilter: false,
        cell: (cellProps: any) => {
          return cellProps.row.index + 1;
        },
      },
      {
        header: "Plan Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Plan Type",
        accessorKey: "plan_type.name",
        enableColumnFilter: false,
      },
      {
        header: "Plan Features",
        accessorKey: "plan_features.name",
        enableColumnFilter: false,
      },
      {
        header: "Validity",
        accessorKey: "validity",
        enableColumnFilter: false,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const PlanData = cellProps.row.original;
                    handlePlanClick(PlanData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const PlanData = cellProps.row.original;
                    onClickDelete(PlanData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handlePlanClick, onClickDelete]
  );

  const addPlan = () => {
    // if (Planaccess?.add === 1) {
    setIsEdit(false);
    toggle();
    validation.setValues({
      id: "",
      name: "",
      Plan_type_id: "",
      Plan_feature_id: "",
      validity: "",
      amount: "",
    });
    // } else {
    //   toast.error("You don't have permission to access this page", {
    //     position: "top-right",
    //     style: {
    //       backgroundColor: "#9b2313",
    //       color: "#c7c7d7",
    //       fontSize: "14px",
    //     },
    //     closeButton: false,
    //   });
    // }
  };

  document.title = " Paal Dappa | Admin panel";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Plan" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="PlanList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Plan List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn text-white add-btn me-1"
                          style={{
                            backgroundColor: "#28a8df",
                            borderColor: "#28a8df",
                          }}
                          id="create-btn"
                          onClick={addPlan}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Plan
                        </button>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={Plan || []}
                      isGlobalFilter={true}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap mb-0"
                      SearchPlaceholder="Search Plan..."
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Plan" : "Add Plan"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field" />

                        <div
                          className="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <Label htmlhtmlFor="id-field1" className="form-label">
                            ID
                          </Label>
                          <Input
                            type="text"
                            id="id-field1"
                            className="form-control"
                            placeholder="ID"
                            readOnly
                          />
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Planname-field"
                            className="form-label"
                          >
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            id="Planname-field"
                            className="form-control"
                            placeholder="Enter Plan Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={(e) => {
                              validation.handleChange(e);
                              DuplicateCheck(e.target.value);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {Duplicate && (
                            <span className="text-danger">{Duplicate}</span>
                          )}
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="status-field"
                            className="form-label"
                          >
                            Plan Type<span className="text-danger">*</span>
                          </Label>

                          <Input
                            name="Plan_type_id"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Plan_type_id || ""}
                            invalid={
                              validation.touched.Plan_type_id &&
                              validation.errors.Plan_type_id
                                ? true
                                : false
                            }
                          >
                            <option>Select a parent Plan...</option>
                            {plantype?.map((item: any, index: any) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.Plan_type_id &&
                          validation.errors.Plan_type_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Plan_type_id}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="status-field"
                            className="form-label"
                          >
                            Plan Features<span className="text-danger">*</span>
                          </Label>

                          <Input
                            name="Plan_feature_id"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Plan_feature_id || ""}
                            invalid={
                              validation.touched.Plan_feature_id &&
                              validation.errors.Plan_feature_id
                                ? true
                                : false
                            }
                          >
                            <option>Select a Features...</option>
                            {features?.map((item: any, index: any) => (
                              <option value={item.id} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.Plan_feature_id &&
                          validation.errors.Plan_feature_id ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Plan_feature_id}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Planname-field"
                            className="form-label"
                          >
                            Validity <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="validity"
                            id="Planname-field"
                            className="form-control"
                            placeholder="Enter Validity"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.validity || ""}
                            invalid={
                              validation.touched.validity &&
                              validation.errors.validity
                                ? true
                                : false
                            }
                          />
                          {validation.touched.validity &&
                          validation.errors.validity ? (
                            <FormFeedback type="invalid">
                              {validation.errors.validity}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlhtmlFor="Planname-field"
                            className="form-label"
                          >
                            Amount <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="amount"
                            id="Planname-field"
                            className="form-control"
                            placeholder="Enter Plan Name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount || ""}
                            invalid={
                              validation.touched.amount &&
                              validation.errors.amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.amount &&
                          validation.errors.amount ? (
                            <FormFeedback type="invalid">
                              {validation.errors.amount}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>

                          <button
                            type="submit"
                            className="btn text-white add-btn me-1"
                            style={{
                              backgroundColor: "#28a8df",
                              borderColor: "#28a8df",
                            }}
                            disabled={Duplicate}
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add Plan"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} centered>
            <ModalHeader toggle={toggleDeleteModal}>
              Confirm Deletion
            </ModalHeader>
            <ModalBody>
              Are you sure you want to delete the Plan{" "}
              <strong>{PlanToDeleteName}</strong>?
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={toggleDeleteModal}
              >
                Cancel
              </Button>
              <Button
                className="btn text-white add-btn me-1"
                style={{
                  backgroundColor: "#28a8df",
                  borderColor: "#28a8df",
                }}
                onClick={() => {
                  confirmDelete(PlanToDeleteId);
                  setModal(false);
                }}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Plan;
