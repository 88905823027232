import React, { useEffect, useState, useMemo } from "react";

import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Row,
  Card,
  Col,
} from "reactstrap";
import classnames from "classnames";

import DeleteModal from "../../Components/Common/DeleteModal";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import { Rating, Published, Price } from "./ProductCol";

import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { deleteProduct, listProduct } from "./_requests";
import { useToast } from "../../Components/Common/ToastContext";
const EcommerceProducts = (props: any) => {

  const { toaster, clearToast } = useToast();

  const [productList, setProductList] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>("1");
  const [product, setProduct] = useState<any>(null);
  console.log("productList state", productList)
  const productLists = () => {
    listProduct().then((res: any) => {
      console.log("res", res)
      console.log("res.data", res.data)
      setProductList(res.data);
    });
  };

  useEffect(() => {
    if (toaster?.message) {
      if (toaster.status === true) {
        toast.success(toaster.message, { autoClose: 3000 });
      } else {
        toast.error(toaster.message, { autoClose: 3000 });
      }

      clearToast(); // Clear the toast data after showing
    }
  }, [toaster, clearToast]);

  useEffect(() => {
    productLists();
  }, []);

  const toggleTab = (tab: any, type: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredProducts = productList;
      if (type !== "all") {
        filteredProducts = productList.filter(
          (product: any) => product.status === type
        );
      }
      setProductList(filteredProducts);
    }
  };

  useEffect(() => {
    const sliderq = document.getElementById("product-price-range");
    sliderq?.setAttribute("data-slider-color", "success");
  }, []);



  const [deleteModalMulti, setDeleteModalMulti] = useState<boolean>(false);

  const [dele, setDele] = useState(0);

  // Displat Delete Button
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element") as HTMLElement;
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = "none";
    } else {
      del.style.display = "block";
    }
  };

  // Delete Multiple
  // const deleteMultiple = () => {
  //   const ele = document.querySelectorAll(".productCheckBox:checked");
  //   const del = document.getElementById("selection-element") as HTMLElement;
  //   ele.forEach((element: any) => {
  //     dispatch(deleteProducts(element.value));
  //     setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
  //     del.style.display = 'none';
  //   });
  // };

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              className="productCheckBox form-check-input"
              value={cell.getValue()}
              onClick={() => displayDelete()}
            />
          );
        },
      },
      {
        header: "Product",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={cell.row.original?.icon}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to={`/products/view/${cell.row.original.id}`}
                    className="text-body"
                  >
                    {" "}
                    {cell.getValue()}
                  </Link>
                </h5>
                <p className="text-muted mb-0">
                  Category :{" "}
                  <span className="fw-medium">
                    {" "}
                    {cell.row.original.categories?.name}
                  </span>
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        header: "Stock",
        accessorKey: "stock",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const variants = cell.row.original.variants;
          return (
            <>
              <p className="text-muted mb-0">
                {variants && variants.length > 0 ? variants[0].stock : "N/A"}
              </p>
            </>
          );
        },
      },
      {
        header: "Price",
        accessorKey: "price",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const variants = cell.row.original.variants;
          return (
            <>
              <p className="text-muted mb-0">
                {variants && variants.length > 0 ? variants[0].mrp : "N/A"}
              </p>
            </>
          );
        },
      },

      {
        header: "Orders",
        accessorKey: "orders",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const orders = cell.getValue();
          return (
            <>
              <p className="text-muted mb-0">{orders ? orders : 20}</p>
            </>
          );
        },
      },
      // {
      //   header: "Rating",
      //   accessorKey: "rating",
      //   enableColumnFilter: false,
      //   cell: (cell: any) => {
      //     return <Rating {...cell} />;
      //   },
      // },
      {
        header: "Published",
        accessorKey: "publish_date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <Published {...cell} />;
        },
      },

    ],
    []
  );
  document.title = " Paal Dappa | Admin panel";

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          // deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title="Products" pageTitle="Ecommerce" />

        <Row>
          <Col xl={12} lg={12}>
            <div>
              <Card>
                <div className="card-header border-0">
                  <Row className=" align-items-center">
                    <Col>
                      <Nav
                        className="nav-tabs-custom card-header-tabs border-bottom-0"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "1" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("1", "all");
                            }}
                            href="#"
                          >
                            All{" "}
                            <span className="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">
                              12
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "2" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("2", "published");
                            }}
                            href="#"
                          >
                            Published{" "}
                            <span className="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">
                              { }
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "3" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("3", "draft");
                            }}
                            href="#"
                          >
                            Draft
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <div className="col-auto">
                      <div id="selection-element">
                        <div className="my-n1 d-flex align-items-center text-muted">
                          Select{" "}
                          <div
                            id="select-content"
                            className="text-body fw-semibold px-1"
                          >
                            {dele}
                          </div>{" "}
                          Result{" "}
                          <button
                            type="button"
                            className="btn btn-link link-danger p-0 ms-3"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
                <div className="card-body pt-0">
                  {/* {productList && productList.length > 0 ? ( */}
                  {productList ? (
                    <TableContainer
                      columns={columns}
                      data={productList || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle table-borderless"
                      theadClass="table-light text-muted"
                      isProductsFilter={true}
                      SearchPlaceholder="Search Products..."
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div>
                        <i className="ri-search-line display-5 text-success"></i>
                      </div>

                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceProducts;
