import React, { useEffect, useState, useMemo } from "react";

import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Row,
  Card,
  Col,
} from "reactstrap";
import classnames from "classnames";

import DeleteModal from "../../Components/Common/DeleteModal";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";

import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { deleteProduct, listProduct } from "./_requests";
import { useToast } from "../../Components/Common/ToastContext";
import { Published } from "./PlanSubscriptionCol";
const EcommercePlanSubscription = (props: any) => {
  const { toaster, clearToast } = useToast();
  const navigate = useNavigate();

  const [productList, setProductList] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>("1");
  const [product, setProduct] = useState<any>(null);

  const productLists = () => {
    listProduct().then((res: any) => {
      console.log("res.data productLists ", res.data);
      console.log("res. productLists ", res);

      // Flatten the products array from all plans
      const allProducts = res?.data?.flatMap((plan: any) => plan.products) || [];

      setProductList(allProducts);
    });
  };

  console.log("productList", productList);
  useEffect(() => {
    if (toaster?.message) {
      if (toaster.status === true) {
        toast.success(toaster.message, { autoClose: 3000 });
      } else {
        toast.error(toaster.message, { autoClose: 3000 });
      }

      clearToast(); // Clear the toast data after showing
    }
    productLists();
  }, [toaster, clearToast]);

  useEffect(() => {
    productLists();
  }, []);

  const toggleTab = (tab: any, type: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredProducts = productList;
      if (type !== "all") {
        filteredProducts = productList.filter(
          (product: any) => product.status === type
        );
      }
      setProductList(filteredProducts);
    }
  };

  useEffect(() => {
    const sliderq = document.getElementById("product-price-range");
    sliderq?.setAttribute("data-slider-color", "success");
  }, []);

  const [deleteModalMulti, setDeleteModalMulti] = useState<boolean>(false);

  const [dele, setDele] = useState(0);

  // Displat Delete Button
  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element") as HTMLElement;
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = "none";
    } else {
      del.style.display = "block";
    }
  };

  const handleEdit = (row: any) => {
    navigate(`/plan-subscription/edit/${row.id}`, { state: { initialValues: row } });
  };
  const handleDelete = (row: any) => {
    navigate(`/plan-subscription/edit/${row.id}`, { state: { initialValues: row } });
  };
  const handleView = (row: any) => {
    navigate(`/plan-subscription/view/${row.id}`, { state: { details: row } });
  };
  
  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              className="productCheckBox form-check-input"
              value={cell.getValue()}
              onClick={() => displayDelete()}
            />
          );
        },
      },
      {
        header: "Plan ",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const { image, details } = cell.row.original;

          return (
            <div className="d-flex align-items-center" onClick={() => handleEdit(cell.row.original)}>
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img src={image} alt="" className="img-fluid d-block" />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to={`/plan-subscription/view/${cell.row.original.id}`}
                    className="text-body"
                  >
                    {cell.getValue()}
                  </Link>
                </h5>
                {details?.map((detail: any, index: number) => (
                  <p className="text-muted mb-0" key={index}>
                    Variant:{" "}
                    <span className="fw-medium">
                      {detail.variant_value} {detail.variant?.name}
                    </span>
                  </p>
                ))}
              </div>
            </div>
          );
        },
      },
      {
        header: "Type",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <p className="text-muted mb-0" onClick={() => handleEdit(cell.row.original)}>{cell.row.original.type_name}</p>;
        },
      },
      {
        header: "Total Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const total_amount = cell.getValue();
          return (
            <p className="text-muted mb-0">
              ₹{total_amount ? total_amount : "0.00"}
            </p>
          
          );
        },
      },
      {
        header: "products ",
        accessorKey: "productName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const details = cell.row.original.details;
          return (
            <p className="text-muted mb-0" onClick={() => handleEdit(cell.row.original)}>
              {details && details.length > 0
                ? details.map((d: any) => `₹${d.product?.name}`).join(", ")
                : "N/A"}
            </p>
          );
        },
      },
      {
        header: "Price",
        accessorKey: "price",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const details = cell.row.original.details;
          return (
            <p className="text-muted mb-0">
              {details && details.length > 0
                ? details.map((d: any) => `₹${d.gross_amount}`).join(", ")
                : "N/A"}
            </p>
          );
        },
      },
      {
        header: "Slot",
        accessorKey: "orders",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const details = cell.row.original.details;
          return (
            <p className="text-muted mb-0">
              {details && details.length > 0
                ? details.map((d: any) => d.slot_type_name).join(", ")
                : "N/A"}
            </p>
          );
        },
      },
      {
        header: "Action",
        accessorKey: "actions",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* View Button */}
              <li className="list-inline-item view" title="View">
                <button
                  className="text-primary d-inline-block border-0 bg-transparent"
                  onClick={() => handleView(cell.row.original)}
                >
                  <i className="ri-eye-fill fs-16"></i>
                </button>
              </li>
  
              {/* Edit Button */}
              <li className="list-inline-item edit" title="Edit">
                <button
                  className="text-primary d-inline-block border-0 bg-transparent"
                  onClick={() => handleEdit(cell.row.original)}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </button>
              </li>
  
              {/* Delete Button (Optional) */}
              <li className="list-inline-item" title="Remove">
                <button
                  className="text-danger d-inline-block border-0 bg-transparent"
                  onClick={() => handleDelete(cell.row.original.id)} // Use correct delete function
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </button>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  // Table Body Rendering with Separate Rows for Variants
  const renderTableRows = (data: any[]) => {
    return data.map((row, rowIndex) => {
      const details = row.details || [];

      return (
        <React.Fragment key={rowIndex}>
          {details.map((detail: any, index: number) => (
            <tr key={`${rowIndex}-${index}`}>
              {index === 0 && (
                <>
                  {/* Checkbox and Plan Name should only appear in the first row */}
                  <td rowSpan={details.length}>
                    <input type="checkbox" className="form-check-input" />
                  </td>
                  <td rowSpan={details.length}>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm bg-light rounded p-1">
                          <img
                            src={row.image}
                            alt=""
                            className="img-fluid d-block"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                          <Link
                            to={`/plan-subscription/view/${row.id}`}
                            className="text-body"
                          >
                            {row.name}
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </td>
                </>
              )}
              {/* Product Name */}
              <td>{row.total_amount}</td>
              <td>{detail.product.name}</td>
              {/* Variant */}
              <td>{`${detail.variant_value} ${detail.variant?.name || ""}`}</td>
              {/* Price */}
              <td>₹{detail.gross_amount}</td>
              {/* Slot */}
              <td>{detail.slot_type_name}</td>
            </tr>
          ))}
        </React.Fragment>
      );
    });
  };

  document.title = " Paal Dappa | Admin panel";

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          // deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title="Plan Subscription" pageTitle="Ecommerce" />

        <Row>
          <Col xl={12} lg={12}>
            <div>
              <Card>
                <div className="card-header border-0">
                  <Row className=" align-items-center">
                    <Col>
                      <Nav
                        className="nav-tabs-custom card-header-tabs border-bottom-0"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "1" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("1", "all");
                            }}
                            href="#"
                          >
                            All{" "}
                            <span className="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">
                              12
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "2" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("2", "published");
                            }}
                            href="#"
                          >
                            Published{" "}
                            <span className="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">
                              5
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames(
                              { active: activeTab === "3" },
                              "fw-semibold"
                            )}
                            onClick={() => {
                              toggleTab("3", "draft");
                            }}
                            href="#"
                          >
                            Draft
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <div className="col-auto">
                      <div id="selection-element">
                        <div className="my-n1 d-flex align-items-center text-muted">
                          Select{" "}
                          <div
                            id="select-content"
                            className="text-body fw-semibold px-1"
                          >
                            {dele}
                          </div>{" "}
                          Result{" "}
                          <button
                            type="button"
                            className="btn btn-link link-danger p-0 ms-3"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
                <div className="card-body pt-0">
                  {productList ? (
                    <TableContainer
                      columns={columns}
                      data={productList || []}
                      isGlobalFilter={true}
                      customPageSize={10}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle table-borderless"
                      theadClass="table-light text-muted"
                      isPlanSubscriptionFilter={true}
                      SearchPlaceholder="Search Plan Subscription..."
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div>
                        <i className="ri-search-line display-5 text-success"></i>
                      </div>

                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EcommercePlanSubscription;
